// src/components/common/Button.js
import React from "react";
import PropTypes from "prop-types";
import styles from "./index.module.css";
const Card = ({ primaryText, secondaryText, className }) => {
  const combinedClassName = `${styles.card} ${
    className ? className : ""
  }`.trim();

  return <div className={combinedClassName}>
    <h3>{secondaryText}</h3>
    <h2>{primaryText}</h2>
  </div>;
};

Card.propTypes = {
  primaryText:PropTypes.any,
  secondaryText: PropTypes.string,
  className: PropTypes.string,
};

export default Card;
