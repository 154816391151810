import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

const CustomRadio = ({ label, value, selectedValue, onChange, color }) => {
  return (
    <FormControlLabel
      control={
        <Radio
          checked={selectedValue === value}
          onChange={onChange}
          value={value}
          sx={{
            color: "var(--button-primary)",
            "&.Mui-checked": {
              color: "var(--button-primary)",
            },
          }}
          inputProps={{ "aria-label": value }}
        />
      }
      label={label}
    />
  );
};
export default CustomRadio;
