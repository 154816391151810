import React from "react";
import Modal from "@mui/material/Modal";
import { useTheme } from "../../../contexts/themeContext";
import styles from "./index.module.css";
import Button from "../button";

const DeleteModal = ({ name, onClick, cancel, open, setOpen, loading }) => {
  const { darkMode } = useTheme();

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      data-theme={darkMode ? "dark" : "light"}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.modal}>
        <h2>Delete {name}</h2>
        <div className={styles.container}>
          <h3>Are you sure you want to delete this loan?</h3>
          <div className={styles.buttons}>
            <Button
              children={"Yes"}
              className={styles.delete}
              onClick={onClick}
              loading={loading}
            />
            <Button
              children={"Cancel"}
              className={styles.input}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteModal;
