import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import usePostData from "../../../hooks/usePostData";
import { useNavigate } from "react-router-dom";
const useCreate = () => {
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/roles`);
  const { postData } = usePostData();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [roles, setRoles] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setRoles(data);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);
  const handleCreate = async (formData) => {
    try {
      setSubmitLoading(true);
      const result = await postData(`/user/create`, {
        ...formData,
      });
      navigate(`/admins/${result?.admin?.id}`);
      setSubmitLoading(false);
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);
      return;
    }
  };
  return { loading, error, roles, handleCreate, submitLoading };
};

export default useCreate;
