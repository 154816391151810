import React from "react";
import CustomRadio from "../../common/customRadio";
import styles from "./index.module.css";

const CustomRadioGroup = ({ label, value, selectedValue, onChange }) => {
  return (
    <div className={styles.radio__div}>
      <h3>{label}</h3>
      <div className={styles.radio__div__inner}>
        <CustomRadio
          label="Yes"
          value={true}
          selectedValue={selectedValue}
          onChange={() => onChange(true)}
        />
        <CustomRadio
          label="No"
          value={false}
          selectedValue={selectedValue}
          onChange={() => onChange(false)}
        />
      </div>
    </div>
  );
};

export default CustomRadioGroup;
