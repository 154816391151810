import { useState, useEffect } from "react";
// import fetchDashboardData from '../../services/fetchDashboardData'; // Adjust path as per your structure
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { useLocation } from "react-router-dom";
import usePostData from "../../../hooks/usePostData";
const useLoans = () => {
  const handleError = useErrorHandler();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const { data, loading, error } = useFetchData(`/loans/${type}`);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loanData, setLoanData] = useState(null);
  const { loading: responseLoading, postData } = usePostData();
  //   const [loading, setLoading] = useState(true);
  //   const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoanData(data);

      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);
  const handleGenerateReport = async () => {
    try {
      const inputData = {
        startDate: startDate,
        endDate: endDate,
        type: type,
      };

      const response = await postData("/loans/report", inputData);
      if (response.url) {
        window.open(response.url, "_blank");
      }
    } catch (error) {
      handleError(error, error.message);
    }
  };
  return {
    loanData,
    loading,
    error,
    startDate,
    endDate,
    setEndDate,
    setStartDate,
    handleGenerateReport,
    responseLoading,
  };
};

export default useLoans;
