import { useState, useEffect } from "react";
// import fetchDashboardData from '../../services/fetchDashboardData'; // Adjust path as per your structure
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";

const useDashboardData = () => {
  const { data, loading, error } = useFetchData("/dashboard");
  const handleError = useErrorHandler();

  const [dashboardData, setDashboardData] = useState(null);
  //   const [loading, setLoading] = useState(true);
  //   const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setDashboardData(data);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);

  return { dashboardData, loading, error };
};

export default useDashboardData;
