// src/hooks/useErrorHandler.js
import { useNotification } from '../contexts/notificationContext';

const useErrorHandler = () => {
  const { showNotification } = useNotification();

  const handleError = (error, defaultMessage = 'An error occurred',success) => {
    console.error(error);
    showNotification(defaultMessage, success? 'success':'error');
  };

  return handleError;
};

export default useErrorHandler;
