import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import useInterest from "./useInterest";
import { Skeleton } from "@mui/material";
import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../common/select";
const Interest = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      if (!user?.admin?.permissions?.permissions?.viewInterestRates) {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);
  const { loading, handleUpdate, submitLoading, productData } = useInterest();
  const [formData, setFormData] = useState(
    productData ? productData?.products : null
  );
  const [penaltyData, setPenaltyData] = useState(null);
  useEffect(() => {
    setFormData(productData?.products);
    setPenaltyData(productData?.penalty);
  }, [productData]);

  const handleChange = (field, interest, type) => (event) => {
    const value = event?.target?.value || event[0];

    // Update the specific object in the formData array
    setFormData((prevFormData) =>
      prevFormData.map((item, i) => {
        // Only update the object at the specified index (fieldIndex)
        if (i === field) {
          if (interest) {
            // Update the nested Interests[0][type] if interest is true
            return {
              ...item,
              Interests: item.Interests.map((interestItem, interestIndex) =>
                interestIndex === 0
                  ? { ...interestItem, [type]: value }
                  : interestItem
              ),
            };
          } else {
            // Otherwise, directly update the type field in the object at fieldIndex
            return {
              ...item,
              [type]: value,
            };
          }
        }
        // Return unchanged items
        return item;
      })
    );
  };
  const handlePenaltyChange = (index, amount) => {
    setPenaltyData((prevFormData) =>
      prevFormData.map((item, i) => {
        // Only update the object at the specified index (fieldIndex)
        if (i === index) {
          // Otherwise, directly update the type field in the object at fieldIndex
          return {
            ...item,
            penalty_fee: amount,
          };
        }
        // Return unchanged items
        return item;
      })
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    
  };

  return (
    <section className={styles.user}>
      {loading ? (
        <div className={styles.form__inner}>
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
        </div>
      ) : (
        <>
          <div className={styles.user__details}>
            <div className={styles.user__name}>
              <h3>Interest Rates</h3>
            </div>
          </div>
          <div className={styles.form} onSubmit={handleSubmit}>
            {formData &&
              formData?.map((product, index) => {
                return (
                  <div className={styles.inner_div}>
                    <h4>{product.name}</h4>
                    <div className={styles.form__inner}>
                      <CustomInput
                        placeholder="Insurance Fee"
                        value={product?.Interests[0]?.insurance_fee}
                        type="text"
                        label="Insurance Fee"
                        onChange={handleChange(index, true, "insurance_fee")}
                        className={styles.input}
                      />
                      <CustomInput
                        placeholder="Monthly Interest"
                        value={product?.Interests[0]?.monthly_interest}
                        type="text"
                        label="Monthly Interest"
                        onChange={handleChange(index, true, "monthly_interest")}
                        className={styles.input}
                      />
                      <CustomInput
                        placeholder="Processing Fee"
                        value={product?.Interests[0]?.processing_fee}
                        type="text"
                        label="Processing Fee"
                        onChange={handleChange(index, true, "processing_fee")}
                        className={styles.input}
                      />
                      <CustomInput
                        placeholder="Minimum Months"
                        value={product?.min_months}
                        type="text"
                        label="Minimum Months"
                        onChange={handleChange(index, false, "min_months")}
                        className={styles.input}
                      />
                      <CustomInput
                        placeholder="Maximum Months"
                        value={product?.max_months}
                        type="text"
                        label="Maximum Months"
                        onChange={handleChange(index, false, "max_months")}
                        className={styles.input}
                      />
                      <CustomInput
                        placeholder="Minimum Amount"
                        value={product?.min_amount}
                        type="text"
                        label="Minimum Amount"
                        onChange={handleChange(index, false, "min_amount")}
                        className={styles.input}
                      />
                      <CustomInput
                        placeholder="Maximum Amount"
                        value={product?.max_amount}
                        type="text"
                        label="Maximum Amount"
                        onChange={handleChange(index, false, "max_amount")}
                        className={styles.input}
                      />
                      <CustomSelect
                        label="Status"
                        options={[
                          { label: "Active", value: "active" },
                          { label: "Inactive", value: "inactive" },
                        ]}
                        value={product?.status}
                        className={styles.input}
                        onChange={handleChange(index, false, "status")}
                      />
                    </div>
                  </div>
                );
              })}
            <div className={styles.form__inner}>
              <div>
                Penalty Ranges
                <div className={styles.penalty}>
                  {penaltyData?.map((range, index) => {
                    return (
                      <CustomInput
                        // placeholder="Processing Fee"
                        value={range.penalty_fee}
                        type="text"
                        label={`${range.min_amount} - ${range.max_amount}`}
                        onChange={(e) => {
                          handlePenaltyChange(index, e.target.value);
                        }}
                        className={styles.input}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            {user?.admin?.permissions?.permissions?.updateInterestRates && (
              <div className={styles.form__buttons}>
                <Button
                  type="cancel"
                  className={`${styles.submit} ${styles.cancel}`}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className={styles.submit}
                  onClick={() => {
                    handleUpdate(formData, penaltyData);
                  }}
                  loading={submitLoading}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default Interest;
