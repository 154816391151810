import React from "react";
import Modal from "@mui/material/Modal";
import { useTheme } from "../../../contexts/themeContext";
import styles from "./index.module.css";
import Button from "../../common/button";
import CustomInput from "../../common/customInput";
import CustomRadio from "../../common/customRadio";
const RequestModal = ({
  onClick,
  open,
  setOpen,
  loading,
  input,
  setInput,
  requestType,
  setRequestType,
  error,
  disabled,
}) => {
  const { darkMode } = useTheme();

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      data-theme={darkMode ? "dark" : "light"}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.modal}>
        <h2>Request</h2>
        <div className={styles.container}>
          <h3>Create a Request</h3>
          <div className={styles.container__inner}>
            <CustomInput
              label={"Remarks"}
              className={styles.modal__input}
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              error={error}
            />
            <div className={styles.radio__group}>
              <CustomRadio
                label="File"
                value={"File"}
                selectedValue={requestType}
                onChange={() => {
                  setRequestType("File");
                }}
              />
              <CustomRadio
                label="Visit"
                value={"Visit"}
                selectedValue={requestType}
                onChange={() => {
                  setRequestType("Visit");
                }}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              children={"Create"}
              onClick={onClick}
              loading={loading}
              disabled={disabled}
            />
            <Button
              children={"Cancel"}
              className={styles.delete}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default RequestModal;
