import React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledBox = styled(Box)(({ width }) => ({
  width: width || "auto",
}));

const CustomDateTimePicker = styled(DateTimePicker)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "var(--border-color)", // Customize border color on hover
    },

    "&.Mui-focused fieldset": {
      borderColor: "var(--border-focus)", // Customize border color when focused
    },
    "&.Mui-disabled fieldset": {
      color: "var(--text-primary)",
    },
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: "var(--text-primary)",
    "-webkit-text-fill-color": "inherit",
  },
  "& .MuiInputLabel-root": {
    color: "var(--border-focus)",
  },

  "& .MuiFormLabel-root": {
    color: "var()", // Customize label color
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "var(--text-heading)", // Customize label color when focused
  },
});

export default function ResponsiveDatePicker({
  label,
  defaultValue,
  width,
  disabled,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledBox width={width}>
        <CustomDateTimePicker
          label={label}
          value={defaultValue ? dayjs(defaultValue) : null}
          sx={{ width: "100%" }}
          disabled={disabled}
          format="DD/MM/YYYY"
        />
      </StyledBox>
    </LocalizationProvider>
  );
}
