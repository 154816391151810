import React, { useState, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import SideBar from "./components/admin/Sidebar";
import Navbar from "./components/admin/Navbar";
import "./App.css";
import Login from "./components/admin/Login";
import { useTheme } from "./contexts/themeContext";
import Dashboard from "./components/admin/Dashboard";
import Users from "./components/admin/Users";
import User from "./components/admin/User";
import Loans from "./components/admin/Loans";
import ApplyForLoan from "./components/admin/apply";
import { AuthContext } from "./contexts/authContext";
import Analytics from "./components/admin/analytics";
import Admins from "./components/admin/admins";
import Admin from "./components/admin/admin";
import Roles from "./components/admin/Roles";
import Role from "./components/admin/Role";
import Interest from "./components/admin/interest";
import EmailSMS from "./components/admin/Email";
import Settings from "./components/admin/settings";
import Profile from "./components/admin/profile";
import UpdatePassword from "./components/admin/updatePassword";
import ForgetPassword from "./components/admin/forgetPassword";
import ResetPassword from "./components/admin/resetPassword";
import Statement from "./components/admin/monthlyStatement";
import CreateAdmin from "./components/admin/createAdmin";
import VerifyAccount from "./components/admin/verifyAccount";
import CreateRole from "./components/admin/createRole";
import Loan from "./components/admin/loan";
import Defaulters from "./components/admin/defaulters";
import Forms from "./components/admin/forms";
import Companies from "./components/admin/companies";
import useInactivityLogout from "./hooks/useInactivity";
import Company from "./components/admin/company";
const App = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { darkMode } = useTheme();
  const location = useLocation();
  const [subMenuActive, setSubMenuActive] = useState("");
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsCollapsed(!isCollapsed);
  };
  const { isAuthenticated, user, setUser } = useContext(AuthContext);
  const isAuthRoute =
    location.pathname === "/login" ||
    location.pathname === "/forget" ||
    location.pathname === "/reset" ||
    location.pathname === "/verify";
  const [active, setActive] = useState(
    location.pathname.split("/")[1]?.split("?")[0]
  );
  useInactivityLogout(user, setUser);
  useEffect(() => {
    setActive(location.pathname.split("/")[1]?.split("?")[0]);
  }, [location.pathname]);
  return (
    <div className="app" data-theme={darkMode ? "dark" : "light"}>
      {isAuthenticated && (
        <>
          <SideBar
            isOpen={isOpen}
            toggleCollapse={toggleDrawer}
            isCollapsed={isCollapsed}
            active={active}
            setActive={setActive}
            subMenuActive={subMenuActive}
            setSubMenuActive={setSubMenuActive}
          />
          <div className="content">
            <Navbar
              isOpen={isOpen}
              active={active}
              subMenuActive={subMenuActive}
            />
            <main className={`main${isAuthRoute ? " full" : ""}`}>
              <Routes>
                {/* <Route path="/" element={<Navigate to="/login" />} /> */}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/users" element={<Users />} />
                <Route path="/roles" element={<Roles />} />

                <Route path="/admins" element={<Admins />} />
                <Route path="/interest" element={<Interest />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/monthly" element={<Statement />} />
                <Route path="/admin/create" element={<CreateAdmin />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/password" element={<UpdatePassword />} />

                <Route path="/users/:id" element={<User />} />
                <Route path="/loans/:id" element={<Loan />} />

                <Route path="/admins/:id" element={<Admin />} />
                <Route path="/roles/:id" element={<Role />} />
                <Route path="/roles/create" element={<CreateRole />} />

                <Route
                  path="/loans"
                  element={<Loans subMenuActive={subMenuActive} />}
                />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/email" element={<EmailSMS />} />

                {/* <Route path="/home" element={<Home />} /> */}
                {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
                <Route path="/apply" element={<ApplyForLoan />} />
                <Route path="/defaulters" element={<Defaulters />} />
                <Route path="/forms" element={<Forms />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/companies/:id" element={<Company />} />
              </Routes>
            </main>
          </div>
        </>
      )}
      {!isAuthenticated && (
        <main className={`main ${isAuthRoute ? "full" : ""}`}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login setActive={setActive} />} />
            <Route path="/forget" element={<ForgetPassword />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/verify" element={<VerifyAccount />} />

            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </main>
      )}
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
