// src/components/Settings.js
import React from "react";
import styles from "./index.module.css";
import useSettings from "./useSettings";
import Skeleton from "@mui/material/Skeleton";
import Button from "./../../common/button";
const Settings = () => {
  const { loading } = useSettings();
  return (
    <section className={styles.dashboard}>
      <div className={styles.cards}>
        {loading ? (
          <>
            <Skeleton variant="rounded" width={"100%"} height={150} />
            <Skeleton variant="rounded" width={"100%"} height={150} />
          </>
        ) : (
          <>
            <div className={styles.card}>
              <div className={styles.card__inner}>
                <h3>Create an Admin</h3>
                <Button children={"Create"} />
              </div>
              <div className={styles.card__inner}>
                <h3>Delete an Admin</h3>
                <Button children={"Delete"} className={styles.delete} />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Settings;
