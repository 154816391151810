import React from "react";
import PropTypes from "prop-types";
import { PieChart } from "@mui/x-charts/PieChart";

const CustomPieChart = ({ data, width, height }) => {
  return (
    <PieChart
      series={[
        {
          data: data.map((item, index) => ({
            id: index,
            value: item.value,
            label: item.label,
          })),
        },
      ]}
    //   width={width}
      height={height}
    />
  );
};

CustomPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

CustomPieChart.defaultProps = {
  width: 400,
  height: 200,
};

export default CustomPieChart;
