import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import Button from "../../common/button";
import Chip from "@mui/material/Chip";
import useRole from "./useRole";
import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomRadioGroup from "./customRadioGroup";
import CustomTable from "../../common/customTable";
import { toTitleCase } from "../../../utils/titleCase";
import { AuthContext } from "./../../../contexts/authContext";

const Role = () => {
  const { user } = useContext(AuthContext);
  const { loading, role, handleDelete } = useRole();
  const [permissions, setPermissions] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: "8px",
    "&.MuiChip-outlined": {
      borderColor: "var(--border-focus)",
      color: "var(--border-focus)",
    },
  }));
  useEffect(() => {
    setPermissions(role?.role);
  }, [role]);

  const getFullName = (value, row) => {
    return `${row.first_name || ""} ${row.sur_name || ""}`;
  };

  const headCells = [
    {
      field: "id",
      headerName: "SR.No",
      type: "number",
    },
    {
      field: "createdAt",
      numeric: false,
      disablePadding: false,
      headerName: "Joined At",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
    },
    {
      field: "fullName",
      numeric: true,
      disablePadding: false,
      headerName: "Name",
      type: "string",
      valueGetter: getFullName,
      flex: 1,
    },
    {
      field: "phone_number",
      numeric: false,
      disablePadding: false,
      headerName: "Phone Number",
      type: "string",
      flex: 1,
    },
    {
      field: "email",
      numeric: true,
      disablePadding: false,
      headerName: "Email",
      type: "email",
      flex: 1,
    },
    {
      field: "status",
      numeric: true,
      disablePadding: false,
      headerName: "Status",
      type: "string",
      valueGetter: (status) => {
        return (status = `${toTitleCase(status)}`);
      },
      flex: 1,
    },
  ];

  return (
    <section className={styles.user}>
      {loading ? (
        <div className={styles.form__inner}>
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
        </div>
      ) : (
        <>
          <div className={styles.user__details}>
            <div className={styles.user__name}>
              <h3>{role?.role?.name}</h3>
            </div>
            {user?.admin?.permissions?.permissions?.deleteRole && (
              <div className={styles.delete__button}>
                <Button children={"Delete"} onClick={handleDelete} />
              </div>
            )}
          </div>
          <div className={styles.form} onSubmit={handleSubmit}>
            <h1>Role Details</h1>
            <div className={styles.radio__div}>
              <CustomRadioGroup
                label="Can Create Admins?"
                value={permissions?.createAdmin}
                selectedValue={permissions?.createAdmin}
              />
              <CustomRadioGroup
                label="Can Update Admins?"
                value={permissions?.updateAdmin}
                selectedValue={permissions?.updateAdmin}
              />
              <CustomRadioGroup
                label="Can Delete Admins?"
                value={permissions?.deleteAdmin}
                selectedValue={permissions?.deleteAdmin}
              />
              <CustomRadioGroup
                label="Can View Admins?"
                value={permissions?.viewAdmin}
                selectedValue={permissions?.viewAdmin}
              />
              <CustomRadioGroup
                label="Can Create Roles?"
                value={permissions?.createRole}
                selectedValue={permissions?.createRole}
              />
              <CustomRadioGroup
                label="Can Delete Roles?"
                value={permissions?.deleteRole}
                selectedValue={permissions?.deleteRole}
              />
              <CustomRadioGroup
                label="Can Email Admins?"
                value={permissions?.emailAdmin}
                selectedValue={permissions?.emailAdmin}
              />
              <CustomRadioGroup
                label="Can SMS Admins?"
                value={permissions?.smsAdmin}
                selectedValue={permissions?.smsAdmin}
              />
              <CustomRadioGroup
                label="Can Email Users?"
                value={permissions?.emailUsers}
                selectedValue={permissions?.emailUsers}
              />
              <CustomRadioGroup
                label="Can SMS Users?"
                value={permissions?.smsUsers}
                selectedValue={permissions?.smsUsers}
              />
              <CustomRadioGroup
                label="Can View Interest Rates?"
                value={permissions?.viewInterestRates}
                selectedValue={permissions?.viewInterestRates}
              />
              <CustomRadioGroup
                label="Can Update Interest Rates?"
                value={permissions?.updateInterestRates}
                selectedValue={permissions?.updateInterestRates}
              />
              <CustomRadioGroup
                label="Can View Users?"
                value={permissions?.viewUser}
                selectedValue={permissions?.viewUser}
              />
              <CustomRadioGroup
                label="Can Update Users?"
                value={permissions?.updateUser}
                selectedValue={permissions?.updateUser}
              />
              <CustomRadioGroup
                label="Can Handle Defaulters?"
                value={permissions?.handleDefaulter}
                selectedValue={permissions?.handleDefaulter}
              />
              <CustomRadioGroup
                label="Can View Defaulters?"
                value={permissions?.viewDefaulters}
                selectedValue={permissions?.viewDefaulters}
              />
              <CustomRadioGroup
                label="Can View Forms?"
                value={permissions?.viewForms}
                selectedValue={permissions?.viewForms}
              />
              <CustomRadioGroup
                label="Can Create Forms?"
                value={permissions?.createForm}
                selectedValue={permissions?.createForm}
              />
              <CustomRadioGroup
                label="Can Delete Forms?"
                value={permissions?.deleteForm}
                selectedValue={permissions?.deleteForm}
              />
              <CustomRadioGroup
                label="Can Update Forms?"
                value={permissions?.updateForm}
                selectedValue={permissions?.updateForm}
              />
              <CustomRadioGroup
                label="Can Apply For Loans?"
                value={permissions?.applyForLoan}
                selectedValue={permissions?.applyForLoan}
              />
              <CustomRadioGroup
                label="Can View Analytics?"
                value={permissions?.viewAnalytics}
                selectedValue={permissions?.viewAnalytics}
              />
              <CustomRadioGroup
                label="Can Generate Monthly Due Statements?"
                value={permissions?.generateMonthlyDueStatement}
                selectedValue={permissions?.generateMonthlyDueStatement}
              />
              <CustomRadioGroup
                label="Can Generate Statistics Statements?"
                value={permissions?.generateStatisticsStatement}
                selectedValue={permissions?.generateStatisticsStatement}
              />
              <CustomRadioGroup
                label="Can View Loans?"
                value={permissions?.viewLoan}
                selectedValue={permissions?.viewLoan}
              />
              <CustomRadioGroup
                label="Can Update Loans?"
                value={permissions?.updateLoan}
                selectedValue={permissions?.updateLoan}
              />
              <CustomRadioGroup
                label="Can Request Loan Items?"
                value={permissions?.requestLoanItem}
                selectedValue={permissions?.requestLoanItem}
              />
              <CustomRadioGroup
                label="Can Review Loans?"
                value={permissions?.reviewLoan}
                selectedValue={permissions?.reviewLoan}
              />
              <CustomRadioGroup
                label="Can Approve Loans?"
                value={permissions?.approveLoan}
                selectedValue={permissions?.approveLoan}
              />
              <CustomRadioGroup
                label="Can Fund Loans?"
                value={permissions?.fundLoan}
                selectedValue={permissions?.fundLoan}
              />
              <CustomRadioGroup
                label="Can Reject Loans?"
                value={permissions?.rejectLoan}
                selectedValue={permissions?.rejectLoan}
              />
              <CustomRadioGroup
                label="Can Pay Installments?"
                value={permissions?.payInstallment}
                selectedValue={permissions?.payInstallment}
              />
              <CustomRadioGroup
                label="Can Extend Loans?"
                value={permissions?.extendLoan}
                selectedValue={permissions?.extendLoan}
              />
              <CustomRadioGroup
                label="Can View Live Loan Reports?"
                value={permissions?.viewLiveLoanReport}
                selectedValue={permissions?.viewLiveLoanReport}
              />
              <CustomRadioGroup
                label="Can Generate Loan Reports?"
                value={permissions?.generateLoanReports}
                selectedValue={permissions?.generateLoanReports}
              />
            </div>
          </div>

          <div className={styles.options}>
            <div className={styles.options__inner}>
              <StyledChip label="Users" onClick={() => {}} variant="outlined" />
            </div>
          </div>
          <div className={styles.table}>
            <CustomTable
              headCells={headCells}
              rows={role?.role.Admins || []}
              type={`Admins`}
            />
          </div>
        </>
      )}
    </section>
  );
};

export default Role;
