import React, { useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import "dayjs/locale/en-gb";
const StyledBox = styled(Box)(({ width }) => ({
  width: width || "auto",
}));

const CustomDateTimePicker = styled(DatePicker)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "var(--border-color)", // Customize border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--border-focus)", // Customize border color when focused
    },
  },
  "& .MuiInputBase-input": {
    // color: "var(--text-heading)", // Customize text color
  },
  "& .MuiFormLabel-root": {
    color: "var(--border-focus)", // Customize label color
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "var(--text-heading)", // Customize label color when focused
  },
});

const ResponsiveDateTimePicker = ({
  label,
  defaultValue,
  width,
  onChange,
  value,
  disableFuture,
  error,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
      <StyledBox width={width}>
        <Tooltip
          title={error ? error : ""}
          disableFocusListener
          disableTouchListener
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          arrow
          placement="top"
        >
          <div>
            <CustomDateTimePicker
              label={label}
              value={dayjs(value)}
              sx={{ width: "100%" }}
              onChange={onChange}
              disableFuture={disableFuture}
            />
          </div>
        </Tooltip>
      </StyledBox>
    </LocalizationProvider>
  );
};

export default ResponsiveDateTimePicker;
