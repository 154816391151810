// src/components/Users.js
import React, { useState, useContext } from "react";
import styles from "./index.module.css";
import CustomTable from "../../common/customTable";
import useUsers from "./useForms";
import { Skeleton } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Modal } from "@mui/material";
import { useTheme } from "../../../contexts/themeContext";
import CustomInput from "./../../common/customInput";
import CustomFileInput from "../../common/customFIleInput";
import Button from "../../common/button";
import { AuthContext } from "./../../../contexts/authContext";
const Forms = () => {
  const { user } = useContext(AuthContext);

  const {
    userData,
    loading,
    handleUpdate,
    handleDelete,
    handleCreate,
    submitLoading,
    setSelectedFile,
  } = useUsers();
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState(null);
  const { darkMode } = useTheme();
  const [file, setFile] = useState(null);
  const handleUploadFileChange = (event) => {
    setFile(event.target.files[0]?.type?.split("/")[1]);
    // handleLoanDataChange(type)({
    //   extension: event.target.files[0]?.type.split("/")[1],
    //   name: type,
    // });
    var reader = new FileReader();
    var file1 = event.target.files[0];
    reader.onload = function (e) {
      setSelectedFile(e.target.result);
    };

    reader.readAsArrayBuffer(file1);
  };
  const [name, setName] = useState("");
  const handleCreateClose = () => {
    setCreate(false);
  };
  const handleCreateOpen = () => {
    setCreate(true);
  };
  const handleUpdateClose = () => {
    setUpdate(false);
  };
  const handleUpdateOpen = (id) => {
    //set the update name by looking in the forms array
    const form = userData?.forms?.find((f) => f.id === id);
    setId(form.id);
    setName(form.name);
    setUpdate(true);
  };

  const handleCreateSubmit = () => {
    const src = document.querySelector(`#file`);
    handleCreate(name, file, src);
  };
  const handleUpdateSubmit = (id) => {
    const src = document.querySelector(`#file`);
    handleUpdate(id, name, file, src);
  };
  const headCells = [
    {
      field: "id",
      headerName: "SR.No",
      type: "number",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      type: "text",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "date",
      flex: 1,
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
    },
    {
      field: "form_link",
      headerName: "Form Link",
      type: "string",
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <>
            {user?.admin?.permissions?.permissions?.viewForms &&
            value?.length > 0 ? (
              <Button
                onClick={() => {
                  const newTab = window.open(value, "_blank");
                  if (newTab) newTab.focus();
                }}
              >
                View
              </Button>
            ) : null}
          </>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      getActions: (params) => {
        const { id } = params;
        return [
          user?.admin?.permissions?.permissions?.updateForm && (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Update"
              onClick={() => handleUpdateOpen(id)}
              color="inherit"
            />
          ),
          user?.admin?.permissions?.permissions?.deleteForm && (
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />
          ),
        ].filter(Boolean); // Filter out any `false` values to avoid errors
      },
    },
  ];

  return (
    <>
      <section className={styles.users}>
        {loading ? (
          <Skeleton width={"90%"} height={"90%"} variant="rounded" />
        ) : (
          <CustomTable
            headCells={headCells}
            rows={userData?.forms || []}
            clickableRows={false}
            type={"Forms"}
            showButton={user?.admin?.permissions?.permissions?.createForm}
            handleButton={handleCreateOpen}
          />
        )}
      </section>
      <Modal
        open={create}
        data-theme={darkMode ? "dark" : "light"}
        onClose={handleCreateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.modal}>
          <h2>Upload a New Form</h2>
          <div className={styles.container}>
            {/* <h3>Name</h3> */}
            <input className={styles.hidden__input} type="file" id="file" />
            <CustomInput
              label={"Name"}
              className={styles.input}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
            <CustomFileInput
              type="file"
              className={styles.input}
              label={"Form"}
              disabled={false}
              // accept=".pdf,.docx"
              onChange={(e) => {
                handleUploadFileChange(e);
              }}
              // error={errors.identityDocument}
            />
            <Button
              children={"Upload"}
              className={styles.input}
              onClick={handleCreateSubmit}
              loading={submitLoading}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={update}
        data-theme={darkMode ? "dark" : "light"}
        onClose={handleUpdateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.modal}>
          <h2>Update Form</h2>
          <div className={styles.container}>
            {/* <h3>Name</h3> */}
            <input className={styles.hidden__input} type="file" id="file" />
            <CustomInput
              label={"Name"}
              className={styles.input}
              // value={updateName}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
            <CustomFileInput
              type="file"
              className={styles.input}
              label={"Form"}
              disabled={false}
              // accept=".pdf,.docx"
              onChange={(e) => {
                handleUploadFileChange(e);
              }}
              // error={errors.identityDocument}
            />
            <Button
              children={"Update"}
              className={styles.input}
              onClick={() => {
                handleUpdateSubmit(id);
              }}
              loading={submitLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Forms;
