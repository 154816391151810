import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

const useInactivityLogout = (user, setUser) => {
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.removeItem("vitpay_data");
    window.dispatchEvent(new Event("storage")); // Notify other tabs
    setUser(null);
    navigate("/login");
  }, [setUser, navigate]);

  useEffect(() => {
    let inactivityTimer;

    const resetInactivityTimer = () => {
      if (inactivityTimer) clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(handleLogout, INACTIVITY_TIMEOUT);
    };

    // Event listeners to detect user activity
    const events = [
      "mousemove",
      "mousedown",
      "keypress",
      "scroll",
      "touchstart",
    ];

    events.forEach((event) => {
      window.addEventListener(event, resetInactivityTimer);
    });

    // Set initial timer
    resetInactivityTimer();

    return () => {
      // Cleanup event listeners and timer on component unmount
      events.forEach((event) => {
        window.removeEventListener(event, resetInactivityTimer);
      });
      if (inactivityTimer) clearTimeout(inactivityTimer);
    };
  }, [handleLogout]);

  return null;
};

export default useInactivityLogout;
