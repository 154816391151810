import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Button from "../button";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--border-color)",
    },
    "&:hover fieldset": {
      borderColor: "var(--border-color)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--border-focus)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "var(--border-focus)",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--border-focus)",
  },
}));

const CustomFileInput = ({
  onChange,
  className,
  placeholder,
  value,
  label,
  variant = "outlined",
  helperText,
  error = false,
  startAdornment,
  endAdornment,
  disabled,
}) => {
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFileName(event.target.files[0].name);
      onChange(event);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Tooltip
        title={error ? error : ""}
        disableFocusListener
        disableTouchListener
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        arrow
        placement="top"
      >
        <StyledTextField
          className={className}
          placeholder={placeholder}
          value={fileName || " "}
          label={label}
          variant={variant}
          helperText={helperText}
          error={error}
          disabled={disabled}
          InputProps={{
            startAdornment: startAdornment,
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  onClick={handleButtonClick}
                  disabled={disabled}
                  children={"Choose"}
                ></Button>
              </InputAdornment>
            ),
            readOnly: true, // Make the text field read-only to prevent manual input
          }}
        />
      </Tooltip>
    </>
  );
};

CustomFileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  helperText: PropTypes.string,
  error: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  disabled: PropTypes.bool,
};

export default CustomFileInput;
