import { useState } from "react";
import apiClient from "../services/api";
import { useAuth } from "../contexts/authContext";

const usePostData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  const postData = async (endpoint, postData) => {
    setLoading(true);
    setError(null);
    setData(null); // Ensure data is reset before making a new request
    try {
      const result = await apiClient.post(endpoint, postData, token);
      setData(result);
      setLoading(false);
      return result; // Return the result to be used in the calling function
    } catch (err) {
      setError(err);
      setLoading(false);
      throw err; // Throw the error to be handled in the calling function
    }
  };

  return { data, loading, error, postData };
};

export default usePostData;
