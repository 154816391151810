import { useState } from "react";
import usePostData from "../../../hooks/usePostData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { useNavigate } from "react-router-dom";
const useReset = () => {
  //   const { data, loading, error } = useFetchData("/user");
  const handleError = useErrorHandler();
  const { postData } = usePostData();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isOTPExpired, setIsOTPExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const navigate = useNavigate();
  //   const [profile, setProfile] = useState(null);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       setProfile(data);
  //       if (error) {
  //         handleError(error, error.message);
  //       }
  //     };

  //     fetchData();
  //   }, [data, handleError, error]);
  const handleSubmitEmail = async (data, email) => {
    try {
      setLoading(true);
      const formData = {
        email: email,
        otp: data?.otp,
        newPassword: data?.password,
      };
      const response = await postData("/user/verify", formData);
      // Handle success
      if (response.message === "OTP has expired") {
        setIsOTPExpired(true);
      }
      setIsEmailSent(true);
      handleError(response, response.message, "success");
      navigate("/login");
      setLoading(false);

      // Handle error
    } catch (error) {
      if (
        error.message === "Error: OTP has expired" ||
        error.message === "Error: Invalid OTP"
      ) {
        setIsOTPExpired(true);
      }
      handleError(error, error.message);

      setLoading(false);
    }
  };
  const handleResendOTP = async (email) => {
    try {
      setOtpLoading(true);

      const response = await postData("/otp/generate", { email });
      // Handle success
      handleError(response, response.message, "success");
      setOtpLoading(false);
      setIsOTPExpired(false);
      // Handle error
    } catch (error) {
      handleError(error, error.message);
      setOtpLoading(false);
    }
  };
  return {
    isEmailSent,
    handleSubmitEmail,
    handleResendOTP,
    isOTPExpired,
    loading,
    otpLoading,
  };
};

export default useReset;
