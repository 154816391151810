// src/components/Dashboard.js
import React from "react";
import styles from "./index.module.css";
import Card from "../../common/card";
import useDashboardData from "./useDashboard";
import Skeleton from "@mui/material/Skeleton";

const Dashboard = () => {
  const { dashboardData, loading } = useDashboardData();
  return (
    <section className={styles.dashboard}>
      <div className={styles.cards}>
        {loading ? (
          <>
            <Skeleton variant="rounded" width={"25%"} height={150} />
            <Skeleton variant="rounded" width={"25%"} height={150} />
            <Skeleton variant="rounded" width={"25%"} height={150} />
            <Skeleton variant="rounded" width={"25%"} height={150} />
            <Skeleton variant="rounded" width={"25%"} height={150} />
            <Skeleton variant="rounded" width={"25%"} height={150} />
            <Skeleton variant="rounded" width={"25%"} height={150} />
            <Skeleton variant="rounded" width={"25%"} height={150} />

          </>
        ) : (
          <>
            <Card
              primaryText={dashboardData?.totalUsers}
              secondaryText={"Total Users"}
            />
            <Card
              primaryText={`K ${dashboardData?.loanAmounts?.loanGiven}`}
              secondaryText={"Total Loans Issued"}
            />
            <Card
              primaryText={`K ${dashboardData?.loanAmounts?.loanReceivable}`}
              secondaryText={"Total Loans Receivable"}
            />
            <Card
              primaryText={`K ${dashboardData?.loanAmounts?.paidLoan}`}
              secondaryText={"Total Paid Amount"}
            />
            <Card
              primaryText={`K ${dashboardData?.loanAmounts?.totalProcessingFee}`}
              secondaryText={"Total Processing Fee"}
            />
            <Card
              primaryText={`K ${dashboardData?.loanAmounts?.totalInterestFee}`}
              secondaryText={"Total Interest Fee"}
            />
            <Card
              primaryText={`K ${dashboardData?.loanAmounts?.totalInsuranceFee}`}
              secondaryText={"Total Insurance Fee"}
            />
            <Card
              primaryText={`K ${dashboardData?.loanAmounts?.totalPenaltyAmount}`}
              secondaryText={"Total Penalty Amount"}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default Dashboard;
