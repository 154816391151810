import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.css";
import CustomSelect from "../../common/select";
import useStatement from "./useStatement";
import Skeleton from "@mui/material/Skeleton";
import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import ResponsiveDatePicker from "../../common/datePicker";
import Button from "../../common/button";
import { validateMonth } from "./validateStatement";
import { AuthContext } from "./../../../contexts/authContext";
const Statement = () => {
  const { user } = useContext(AuthContext);

  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    "& > :not(style) ~ :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));

  const {
    employerData,
    loading,
    handleMonthlyStatement,
    loadingStatement,
    handleGenerateReport,
    loadingReport,
    productData,
  } = useStatement();

  const [monthSelect, setMonthSelect] = useState("");
  const [type, setType] = useState("");
  const [employer, setEmployer] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [disableDue, setDisableDue] = useState(true);
  useEffect(() => {
    if (employerData) {
      const employerList = employerData?.employers?.map((emp) => ({
        value: emp,
        label: emp,
      }));
      setEmployers(employerList);
    }
  }, [employerData]);
  const handleErrors = (field) => (event) => {
    setErrors((prevState) => ({
      ...prevState,
      [field]: event,
    }));
  };
  const monthsArray = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ];

  const loanTypes = productData
    ? productData?.products?.map((product) => {
        return { value: product.id, label: product.name };
      })
    : [];
  useEffect(() => {
    if (!monthSelect || errors.month || employer.length <= 0) {
      setDisableDue(true);
    } else {
      setDisableDue(false);
    }
  }, [monthSelect, errors, employer]);
  return (
    <section className={styles.dashboard}>
      <div className={styles.cards}>
        {loading ? (
          <>
            <Skeleton variant="rounded" width={"25%"} height={150} />
            <Skeleton variant="rounded" width={"25%"} height={150} />
            <Skeleton variant="rounded" width={"25%"} height={150} />
          </>
        ) : (
          <>
            {user?.admin?.permissions?.permissions
              ?.generateMonthlyDueStatement && (
              <div className={styles.div}>
                <h3>Generate Monthly Statement</h3>
                <CustomSelect
                  options={monthsArray}
                  className={styles.input}
                  label={"Month"}
                  value={monthSelect}
                  onChange={(e) => {
                    handleErrors("month")(validateMonth(e[0]));
                    setMonthSelect(e[0]);
                  }}
                  error={errors.month}
                />
                <CustomSelect
                  options={employers}
                  className={styles.input}
                  label={"Employer"}
                  value={employer}
                  onChange={(e) => {
                    // handleErrors("month")((e[0]));
                    setEmployer(e);
                  }}
                  error={errors.month}
                />
                <Button
                  children={"Generate Report"}
                  className={styles.button}
                  onClick={() => {
                    handleMonthlyStatement(monthSelect, employer);
                  }}
                  loading={loadingStatement}
                  disabled={disableDue}
                />
              </div>
            )}
            <div className={styles.divider}>
              <Root>
                <Divider>OR</Divider>
              </Root>
            </div>
            {user?.admin?.permissions?.permissions
              ?.generateStatisticsStatement && (
              <div className={styles.div}>
                <h3>Generate Statistics Report</h3>
                <div className={styles.form}>
                  <div className={styles.component}>
                    <h3>Date Range</h3>
                    <div className={styles.component__inner}>
                      <ResponsiveDatePicker
                        onChange={(value) => {
                          const date = new Date(value);
                          const formattedDate = new Date(
                            Date.UTC(
                              date.getFullYear(),
                              date.getMonth(),
                              date.getDate()
                            )
                          );
                          setStartDate(formattedDate);
                        }}
                        value={startDate}
                        disableFuture={true}
                      />
                      <ResponsiveDatePicker
                        onChange={(value) => {
                          const date = new Date(value);
                          const formattedDate = new Date(
                            Date.UTC(
                              date.getFullYear(),
                              date.getMonth(),
                              date.getDate()
                            )
                          );

                          setEndDate(formattedDate);
                        }}
                        value={endDate}
                        disableFuture={true}
                      />
                    </div>
                  </div>
                  <div className={styles.component}>
                    <h3>Loan Type</h3>
                    <div className={styles.component__inner}>
                      <CustomSelect
                        label={"Loan Type"}
                        options={loanTypes}
                        className={styles.input}
                        value={type}
                        onChange={(e) => {
                          setType(e[0]);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.component}>
                    <h3>Employer</h3>
                    <div className={styles.component__inner}>
                      <CustomSelect
                        label={"Employer"}
                        multiple={true}
                        value={employer}
                        options={employers || []}
                        className={styles.input}
                        onChange={(newValue) => setEmployer(newValue)}
                      />
                    </div>
                  </div>
                  <Button
                    children={"Generate Report"}
                    className={styles.button}
                    onClick={() => {
                      handleGenerateReport(employer, startDate, endDate, type);
                    }}
                    loading={loadingReport}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default Statement;
