import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { useParams } from "react-router-dom";
import usePostData from "../../../hooks/usePostData";
const useCompany = () => {
  const { id } = useParams();
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/company/${id}`);
  const { postData } = usePostData();
  const [companyData, setCompanyData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  //   const [popupLoading, setPopupLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setCompanyData(data?.company);

      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);
  //   const handleDelete = async () => {
  //     try {
  //       await postData(`/users/delete`, { id: id });
  //     } catch (error) {
  //       handleError(error, error.message);
  //       return;
  //     }
  //   };
  const handleUpdate = async (company) => {
    try {
      setSubmitLoading(true);
      await postData(`/company/update`, { id: id, ...company });
      setSubmitLoading(false);
      window.location.reload();
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);

      return;
    }
  };
  //   const handleDefaulter = async (remarks) => {
  //     setPopupLoading(true);
  //     try {
  //       await postData(`/defaulters`, {
  //         userId: companyData?.user?.id,
  //         reason: remarks,
  //       });
  //       setPopupLoading(false);
  //       window.location.reload();
  //     } catch (error) {
  //       handleError(error, error.message);
  //       return;
  //     }
  //   };
  return {
    companyData,
    loading,
    error,
    // handleDelete,
    handleUpdate,
    submitLoading,
    // handleDefaulter,
    // popupLoading,
  };
};

export default useCompany;
