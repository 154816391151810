import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "../../common/button";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import styles from "./index.module.css";
import { useTheme } from "../../../contexts/themeContext";
const TimingPopup = ({ timingClick, selected }) => {
  const { darkMode } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTimingClick = (timing) => {
    timingClick(timing);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        children={selected}
      ></Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        data-theme={darkMode ? "dark" : "light"}

        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Button
            className={styles.button}
            onClick={() => handleTimingClick("Daily")}
          >
            Daily
          </Button>
          <Button
            className={styles.button}
            onClick={() => handleTimingClick("Weekly")}
          >
            Weekly
          </Button>
          <Button
            className={styles.button}
            onClick={() => handleTimingClick("Monthly")}
          >
            Monthly
          </Button>
        </Box>
      </Popover>
    </div>
  );
};

TimingPopup.propTypes = {
  timingClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

export default TimingPopup;
