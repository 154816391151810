// src/contexts/authContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import authService from "../services/authServices";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await authService.getCurrentUser();
        setUser(userData);
      } catch (error) {
        // console.error(error);
        setUser(null);
      }
    };
    fetchUser();
  }, []);
  const token = () => {
    const tokenData = localStorage.getItem("vitpay_data");
    return tokenData ? JSON.parse(tokenData).token : null;
  };
  const login = async (credentials) => {
    try {
      const data = await authService.login(credentials);
      const userData = await authService.getCurrentUser();
      setUser(userData);
      return data;
    } catch (error) {
      console.error("Login failed", error);
      throw error;
    }
  };

  const logout = () => {
    authService.logout();
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        setUser,
        token: token(),
        isAuthenticated: authService.isAuthenticated(),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};
