import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.css";
import CustomRadio from "./../../common/customRadio";
import CustomSelect from "../../common/select";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import useEmail from "./useEmail";
import { Skeleton } from "@mui/material";
import { AuthContext } from "./../../../contexts/authContext";
export default function EmailSMS() {
  const { users, loading, admins, handleSend, submitLoading } = useEmail();
  const [adminMap, setAdminMap] = useState(null);
  const [userMap, setUserMap] = useState(null);
  const { user: AuthenticatedUser } = useContext(AuthContext);

  useEffect(() => {
    const adminData = admins?.users?.map((admin) => {
      return {
        label: `${admin.firstName} ${admin.surName}`,
        value: admin.id,
      };
    });
    setAdminMap(adminData);
    const userData = users?.users?.map((user) => {
      return {
        label: `${user.firstName} ${user.surName}`,
        value: user.id,
      };
    });
    setUserMap(userData);
  }, [users, admins]);
  const [notificationType, setNotificationType] = useState(null);
  const [userType, setUserType] = useState(null);
  const [emailSubject, setEmailSubject] = useState(null);
  const [emailBody, setEmailBody] = useState(null);
  const [smsBody, setSmsBody] = useState(null);
  const [user, setUser] = useState([]);
  const handleNext = () => {
    handleSend(
      userType === 0 ? "users" : "admins",
      user,
      emailSubject,
      emailBody,
      smsBody,
      notificationType === 0 ? "sms" : "email"
    );
  };

  return (
    <section className={styles.apply}>
      {loading ? (
        <div className={styles.loading}>
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
        </div>
      ) : (
        <div className={styles.form}>
          <div className={styles.form__inner}>
            <div className={styles.component}>
              {(AuthenticatedUser?.admin?.permissions?.permissions?.smsUsers ||
                AuthenticatedUser?.admin?.permissions?.permissions
                  ?.emailUsers) && (
                <CustomRadio
                  label={"Users"}
                  onChange={() => {
                    setUserType(0);
                  }}
                  value={0}
                  selectedValue={userType}
                />
              )}
              {(AuthenticatedUser?.admin?.permissions?.permissions?.smsAdmin ||
                AuthenticatedUser?.admin?.permissions?.permissions
                  ?.emailAdmin) && (
                <CustomRadio
                  label={"Admins"}
                  onChange={() => {
                    setUserType(1);
                  }}
                  value={1}
                  selectedValue={userType}
                />
              )}
            </div>
            {userType !== null && (
              <div className={styles.component}>
                {(AuthenticatedUser?.admin?.permissions?.permissions
                  ?.smsUsers ||
                  AuthenticatedUser?.admin?.permissions?.permissions
                    ?.smsAdmin) && (
                  <CustomRadio
                    label={"SMS"}
                    onChange={() => {
                      setNotificationType(0);
                    }}
                    value={0}
                    selectedValue={notificationType}
                  />
                )}
                {(AuthenticatedUser?.admin?.permissions?.permissions
                  ?.emailAdmin ||
                  AuthenticatedUser?.admin?.permissions?.permissions
                    ?.emailUsers) && (
                  <CustomRadio
                    label={"Email"}
                    onChange={() => {
                      setNotificationType(1);
                    }}
                    value={1}
                    selectedValue={notificationType}
                  />
                )}
              </div>
            )}
            {notificationType !== null && (
              <div className={styles.component}>
                {userType === 1 ? (
                  <CustomSelect
                    label={"Admins"}
                    options={adminMap}
                    className={styles.input}
                    multiple={true}
                    value={user}
                    onChange={(newValue) => {
                      setUser(newValue);
                    }}
                  />
                ) : (
                  <CustomSelect
                    label={"Users"}
                    options={userMap}
                    className={styles.input}
                    multiple={true}
                    value={user}
                    onChange={(newValue) => {
                      setUser(newValue);
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className={styles.form__inner}>
            {userType !== null && notificationType !== null && (
              <div className={styles.component}>
                {notificationType === 1 ? (
                  <>
                    <CustomInput
                      label={"Email Subject"}
                      type="text"
                      className={styles.input}
                      multiline={false}
                      value={emailSubject}
                      onChange={(e) => {
                        setEmailSubject(e.target.value);
                      }}
                    />
                    <CustomInput
                      label={"Email Body"}
                      type="text"
                      multiline={true}
                      className={styles.input}
                      value={emailBody}
                      onChange={(e) => {
                        setEmailBody(e.target.value);
                      }}
                    />
                  </>
                ) : (
                  <CustomInput
                    label={"SMS Body"}
                    type="text"
                    multiline={true}
                    className={styles.input}
                    value={smsBody}
                    onChange={(e) => {
                      setSmsBody(e.target.value);
                    }}
                  />
                )}
              </div>
            )}
          </div>

          <div className={styles.buttons}>
            <Button
              className={styles.prev}
              onClick={() => {
                window.location.reload();
              }}
            >
              Cancel
            </Button>
            {((emailBody !== null && emailSubject !== null) ||
              smsBody !== null) && (
              <Button
                onClick={handleNext}
                className={styles.next}
                loading={submitLoading}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      )}
    </section>
  );
}
