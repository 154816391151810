export const validateLoanType = (value) => {
  if (!value) return "Loan Type is required.";
  return null;
};

export const validateAddressDuration = (value) => {
  if (!value) return "Address Duration is required.";
  return null;
};

export const validateNetMonthlyIncome = (value) => {
  if (!value) return "Net Monthly Income is required.";
  return null;
};

export const validateEmploymentStatus = (value) => {
  if (!value) return "Employment Status is required.";
  return null;
};

export const validatePublicService = (value) => {
  if (!value) return "Public Service is required.";
  return null;
};
export const validateRemarks = (value) => {
  if (!value) return "Remarks are required.";
  return null;
};
export const validateEmployer = (value) => {
  if (!value) return "Employer is required.";
  return null;
};

export const validateEmployeeNumber = (value) => {
  if (!value) return "Employee Number is required.";
  return null;
};
export const validateAmount = (value) => {
  if (!value) return "Amount is required.";
  return null;
};
export const validateDocument = (file) => {
  if (file) {
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "pdf",
      "msword",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!allowedTypes.includes(file.extension)) {
      return "Please upload a valid document type (JPEG, PNG, PDF, DOC, DOCX).";
    } else {
      return null;
    }
  }

  return null;
};
export const validateLoanInformation = (formData) => {
  const errors = {};

  errors.loanType = validateLoanType(formData.loanType);
  errors.addressDuration = validateAddressDuration(formData.addressDuration);
  errors.netMonthlyIncome = validateNetMonthlyIncome(formData.netMonthlyIncome);
  errors.employmentStatus = validateEmploymentStatus(formData.employmentStatus);
  errors.publicService = validatePublicService(formData.publicService);
  errors.employer = validateEmployer(formData.employer);
  errors.employeeNumber = validateEmployeeNumber(formData.employeeNumber);
  Object.keys(errors).forEach((key) => {
    if (errors[key] === null) {
      delete errors[key];
    }
  });
  return errors;
};
