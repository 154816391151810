import { useState } from "react";
// import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import usePostData from "../../../hooks/usePostData";
const usePassword = () => {
  //   const { data, loading, error } = useFetchData("/user");
  const { postData } = usePostData();
  const handleError = useErrorHandler();
  const [submitLoading, setSubmitLoading] = useState(false);
  //   const [profile, setProfile] = useState(null);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       setProfile(data);
  //       if (error) {
  //         handleError(error, error.message);
  //       }
  //     };

  //     fetchData();
  //   }, [data, handleError, error]);
  const handleUpdatePassword = async (newPassword, oldPassword) => {
    try {
      setSubmitLoading(true);
      const formData = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      await postData("/user/password/update", formData);
      // Handle success
      setSubmitLoading(false);

      // Handle error
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);
    }
  };
  return { handleUpdatePassword, submitLoading };
};

export default usePassword;
