export const validateFirstName = (value) => {
  if (!value) return "First Name is required.";
  if (value.length < 2) return "First Name must be at least 2 characters long.";
  return null;
};

export const validateSurName = (value) => {
  if (!value) return "Surname is required.";
  if (value.length < 2) return "Surname must be at least 2 characters long.";
  return null;
};

export const validatePhoneNumber = (value) => {
  const ukRegex = /^\+44[0-9]{10}$/; // UK phone number format: +44 followed by 10 digits
  const zambiaRegex = /^\+260[0-9]{9}$/; // Zambia phone number format: +260 followed by 9 digits
  const pakistanRegex = /^\+92[0-9]{10}$/; // Pakistan phone number format: +92 followed by 10 digits

  if (!value) return "Phone Number is required.";
  if (
    !ukRegex.test(value) &&
    !zambiaRegex.test(value) &&
    !pakistanRegex.test(value)
  ) {
    return "Phone Number is invalid.";
  }

  return null;
};

export const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value) return "Email is required.";
  if (!emailRegex.test(value)) return "Email is invalid.";
  return null;
};

export const validateUser = (formData) => {
  const errors = {};

  errors.firstName = validateFirstName(formData.firstName);
  errors.surName = validateSurName(formData.surName);
  errors.phoneNumber = validatePhoneNumber(formData.phoneNumber);
  errors.email = validateEmail(formData.email);

  Object.keys(errors).forEach((key) => {
    if (errors[key] === null) {
      delete errors[key];
    }
  });
  return errors;
};
