const React = require("react");
const {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  PDFDownloadLink,
} = require("@react-pdf/renderer");
Font.register({
  family: "Open Sans",
  src: "https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0e.ttf",
});
const Logo = require("./../../../images/Logo.png");
const styles = StyleSheet.create({
  page: {
    fontFamily: "Open Sans",
    padding: 30,
    fontSize: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    width: 100,
    height: 30,
    marginBottom: 20,
  },
  companyInfo: {
    marginTop: 5,
  },
  rightHeader: {
    alignItems: "flex-end",
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
  },
  amount: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 5,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    backgroundColor: "#0062dc",
    color: "white",
    padding: 5,
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  column: {
    flex: 1,
  },
  bold: {
    fontWeight: "bold",
    color: "#0062dc",
  },
  footer: {
    marginTop: 20,
    borderTopWidth: 1,
    borderTopColor: "#000",
    paddingTop: 10,
  },
  table: {
    borderWidth: 1,
    borderColor: "#000",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  tableCell: {
    flex: 1,
    padding: 5,
    borderRightWidth: 1,
    borderRightColor: "#000",
  },
  tableCellLast: {
    flex: 1,
    padding: 5,
  },
});

const LoanStatement = ({ loanDetails }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <View>
          <Image style={styles.logo} src={Logo} />
          <Text>
            {loanDetails.user?.first_name} {loanDetails.user?.sur_name}
          </Text>
          <Text>User Id: {loanDetails.user?.user_id}</Text>
          <Text>Phone Number: {loanDetails.user?.phone_number}</Text>
          <Text>NRC Number: {loanDetails.user?.nrc_number}</Text>
          <Text>
            {loanDetails.user?.residential_address_number}{" "}
            {loanDetails.user?.street_name}
          </Text>
          <Text>
            {loanDetails.user?.residential_area} {loanDetails.user?.nationality}
          </Text>
        </View>
        <View style={styles.rightHeader}>
          {loanDetails.loanType && (
            <Text style={styles.title}>{loanDetails.loanType}</Text>
          )}
          <Text>Loan Id: {loanDetails.loanId}</Text>
          <Text>Total Amount</Text>
          <Text style={styles.amount}>K{loanDetails.totalRepayment}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Loan Details</Text>
        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.bold}>Payment overview</Text>
            <Text>Principal: K{loanDetails.loanAmount}</Text>
            <Text>Interest: K{loanDetails.monthlyInterest}</Text>
            <Text>Insurance: K{loanDetails.insuranceFee}</Text>
            <Text>Processing Fee: K{loanDetails.processingFee}</Text>
            <Text style={styles.bold}>
              Total: K{loanDetails.totalRepayment}
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.bold}>Loan Overview</Text>
            <Text>
              Loan Duration: {loanDetails.loanDuration}{" "}
              {loanDetails.loanDuration === 1 ? "Month" : "Months"}
            </Text>
            <Text>Loan status: {loanDetails.status.toUpperCase()}</Text>
            <Text>Interest Rate: {loanDetails.interestRate}%</Text>
            <Text>Insurance Rate:{loanDetails.insuranceRate}%</Text>
            <Text>Processing Rate: {loanDetails.processingRate}%</Text>
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Current Loan Overview</Text>
        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.bold}>Paid Loan Overview</Text>
            <Text>Interest Paid: K{loanDetails.paidInterest}</Text>
            <Text>Insurance Paid: K{loanDetails.paidInsurance}</Text>
            <Text>Processing Fee Paid: K{loanDetails.paidProcessing}</Text>
            <Text style={styles.bold}>Total Paid: K{loanDetails.paidLoan}</Text>
          </View>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Loan Installments Plan</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Due Date</Text>
            <Text style={styles.tableCell}>Amount</Text>
            <Text style={styles.tableCell}>Amount Paid</Text>
            <Text style={styles.tableCell}>Status</Text>
          </View>
          {loanDetails.installments?.map((installment) => {
            return (
              <View style={styles.tableRow} key={installment.id}>
                <Text style={styles.tableCell}>
                  {new Date(installment?.due_date)?.toLocaleDateString("en-GB")}
                </Text>
                <Text style={styles.tableCell}>K{installment.amount}</Text>
                <Text style={styles.tableCell}>K{installment.amount_paid}</Text>
                <Text style={styles.tableCellLast}>
                  {installment.date_paid ? "Paid" : "Pending"}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
      {/* <View style={styles.section}>
        <Text style={styles.bold}>Note:</Text>
        <Text>
          Please Review these details carefully and review the terms and
          conditions then submit the loan application.
        </Text>
      </View> */}
      <View style={styles.section}>
        <Text style={styles.bold}>Questions?</Text>
        <Text>
          Visit https://vitpayzambia.com or call (855) 624-8246. Hours: M-Th 9am
          - 8pm and F 9am - 5pm ET.
        </Text>
      </View>
    </Page>
  </Document>
);

const LoanPDFDownload = ({ loanDetails }) => (
  <PDFDownloadLink
    document={<LoanStatement loanDetails={loanDetails} />}
    fileName="Loan_Summary.pdf"
    style={{
      textDecoration: "none",
      color: "var(--text-primary)",
      fontSize: "1.2rem",
      fontWeight: "bold",
      backgroundColor: "var(--button-primary)",
      display: "inline-block",
      padding: "1rem 2rem",
      borderRadius: "4px",
    }}
  >
    Download Loan Summary
  </PDFDownloadLink>
);
export default LoanPDFDownload;
