import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import usePostData from "../../../hooks/usePostData";
import { useNavigate } from "react-router-dom";
const useApply = () => {
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/users`);
  const { data: interestRate, loading: loadingInterest } =
    useFetchData(`/admins/interest`);
  const { data: products } = useFetchData(`/products`);
  const [productData, setProductData] = useState(null);
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  const [applyLoading, setApplyLoading] = useState(false);
  const [userData, setUserData] = useState({
    title: "",
    firstName: "",
    surName: "",
    gender: "",
    phoneNumber: "",
    NRCNumber: "",
    dateOfBirth: "",
    email: "",
    residentialAddressNumber: "",
    streetName: "",
    residentialArea: "",
    nationality: "",
    bankName: "",
    accountNumber: "",
  });
  const handleUserDataChange = (field) => (event) => {
    setUserData((prevState) => ({
      ...prevState,
      [field]: event?.target?.value || event,
    }));
  };
  const [loanData, setLoanData] = useState({
    loanType: "",
    addressDuration: "",
    netMonthlyIncome: "",
    employmentStatus: "",
    publicService: "",
    employer: "",
    employeeNumber: "",
    loanAmount: "",
    loanPeriod: "",
    interestFee: "",
    processingFee: "",
    insuranceFee: "",
    penaltyFee: " 0 ",
    monthlyInstallments: "",
    total: "",
    identityDocument: null,
    paySlip1: null,
    paySlip2: null,
    proofOfAddress: null,
    preApprovedLetter: null,
  });
  const handleLoanDataChange = (field) => (event) => {
    setLoanData((prevState) => ({
      ...prevState,
      [field]: event?.target?.value || event,
    }));
  };
  const { postData } = usePostData();

  useEffect(() => {
    const fetchData = async () => {
      setUsers(data);
      setProductData(products);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error, products]);
  const handleApplyLoan = async (
    identity,
    pay,
    pay2,
    pre,
    address,
    bank,
    userType
  ) => {
    setApplyLoading(true);
    try {
      const formData = {
        ...loanData,
        ...userData,
        isUser: userType === 0 ? true : false,
        userId: user,
      };
      const response = await postData("/loan/apply", formData);

      if (response?.uploadUrls) {
        const url = response.uploadUrls;

        const res = await fetch(identity);
        const blob = await res.blob();
        await fetch(url?.identityDocument, {
          method: "PUT",
          body: blob,
        });

        const respay = await fetch(pay);
        const blobPay = await respay.blob();
        await fetch(url?.payslip1, {
          method: "PUT",
          body: blobPay,
        });

        const respay1 = await fetch(pay2.src);
        const blobpay1 = await respay1.blob();
        await fetch(url?.payslip2, {
          method: "PUT",
          body: blobpay1,
        });

        const resBank = await fetch(bank);
        const blobBank = await resBank.blob();
        await fetch(url?.bankStatement, {
          method: "PUT",
          body: blobBank,
        });

        const respre = await fetch(pre?.src);
        const blobpre = await respre?.blob();
        await fetch(url?.preApprovedLetter, {
          method: "PUT",
          body: blobpre,
        });

        const resaddress = await fetch(address.src);
        const blobaddress = await resaddress.blob();
        await fetch(url?.addressProof, {
          method: "PUT",
          body: blobaddress,
        });

        navigate(`/loans/${response.loan.id}`);
        setApplyLoading(false);
      }
    } catch (error) {
      handleError(error, error.message);
      setApplyLoading(false);
    }
  };
  return {
    users,
    loading,
    user,
    setUser,
    userData,
    handleUserDataChange,
    loanData,
    handleLoanDataChange,
    handleApplyLoan,
    applyLoading,
    interestRate,
    loadingInterest,
    productData,
  };
};

export default useApply;
