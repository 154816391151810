// src/components/Users.js
import React, { useEffect, useContext } from "react";
import styles from "./index.module.css";
import CustomTable from "../../common/customTable";
import useUsers from "./useUsers";
import { toTitleCase } from "../../../utils/titleCase";
import { Skeleton } from "@mui/material";
import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
const Defaulters = () => {
  const { userData, loading } = useUsers();

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      if (!user?.admin?.permissions?.permissions?.viewDefaulters) {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);
  const getFullName = (value, row) => {
    return `${row.firstName || ""} ${row.surName || ""}`;
  };
  const headCells = [
    {
      field: "id",
      headerName: "SR.No",
      type: "number",
    },
    {
      field: "userId",
      headerName: "User ID",
      type: "text",
    },
    {
      field: "createdAt",
      numeric: false,
      disablePadding: false,
      headerName: "Joined At",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
    },
    {
      field: "fullName",
      numeric: true,
      disablePadding: false,
      headerName: "Name",
      type: "string",
      valueGetter: getFullName,
      flex: 1,
    },
    {
      field: "phoneNumber",
      numeric: false,
      disablePadding: false,
      headerName: "Phone Number",
      type: "string",
      flex: 1,
    },
    {
      field: "email",
      numeric: true,
      disablePadding: false,
      headerName: "Email",
      type: "email",
      flex: 1,
    },
    {
      field: "status",
      numeric: true,
      disablePadding: false,
      headerName: "Status",
      type: "string",
      valueGetter: (status) => {
        return (status = `${toTitleCase(status)}`);
      },
      flex: 1,
    },
  ];

  return (
    <section className={styles.users}>
      {loading ? (
        <Skeleton width={"90%"} height={"90%"} variant="rounded" />
      ) : (
        <CustomTable
          headCells={headCells}
          rows={userData?.defaulters || []}
          type={"Users"}
        />
      )}
    </section>
  );
};

export default Defaulters;
