import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--border-color)",
    },
    "&:hover fieldset": {
      borderColor: "var(--border-color)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--border-focus)",
    },
    "&.Mui-disabled fieldset": {
      color: "var(--text-primary)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "var(--border-focus)",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: "var(--text-primary)",
    "-webkit-text-fill-color": "inherit",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--border-focus)",
  },
  "& .MuiSelect-select": {
    color: "var(--text-color)",
  },
  "& .MuiMenuItem-root": {
    color: "var(--text-color)",
  },
}));

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  className,
  multiple,
  disabled,
  error,
}) => {
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      onChange(options.map((option) => option.value));
    } else {
      onChange([]);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value);
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      title={error ? error : ""}
      disableFocusListener
      disableTouchListener
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      arrow
      placement="top"
    >
      <StyledFormControl className={className}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          onChange={handleChange}
          autoWidth
          label={label}
          multiple={multiple}
          disabled={disabled}
          error={error}
          renderValue={(selected) =>
            Array?.isArray(selected)
              ? selected
                  ?.map((val) => options?.find((opt) => opt?.value === val)?.label)
                  ?.join(", ")
              : options?.find((opt) => opt.value === selected)?.label
          }
          MenuProps={{
            PaperProps: {
              sx: {
                "& .MuiMenuItem-root": {
                  color: "var(--text-color)",
                },
              },
            },
          }}
        >
          {multiple && (
            <MenuItem value="select-all">
              <Checkbox
                checked={value.length === options.length}
                indeterminate={
                  value.length > 0 && value.length < options.length
                }
                onChange={handleSelectAll}
              />
              <ListItemText primary="Select All" />
            </MenuItem>
          )}
          {options?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {multiple && (
                <Checkbox checked={value.indexOf(option.value) > -1} />
              )}
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </Tooltip>
  );
};

export default CustomSelect;
