// src/services/authService.js
const BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_FRONTEND_URL
    : process.env.REACT_APP_LOCAL_URL;

// const handleError = useErrorHandler()
const authService = {
  login: async (credentials) => {
    try {
      const response = await fetch(`${BASE_URL}/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });
      const data = await response.json();
      if (response.status !== 200) {
        throw new Error(data.message);
      }
      if (data.admin.loginAttempt === 1) {
      } else {
        localStorage.setItem("vitpay_data", JSON.stringify(data));
      }
      return data;
    } catch (error) {
      // handleError(error, "Login failed");
      throw error; // Rethrow the error after handling
    }
  },

  logout: () => {
    localStorage.removeItem("vitpay_data");
  },

  getCurrentUser: async (handleError) => {
    try {
      const tokenData = localStorage.getItem("vitpay_data");
      if (!tokenData) {
        return null;
      }
      const token = JSON.parse(tokenData);
      const response = await fetch(`${BASE_URL}/user`, {
        method: "GET",
        headers: {
          Authorization: `${token.token}`,
        },
      });
      if (response.status === 403) {
        localStorage.removeItem("vitpay_data");
        return false;
      }
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      
      return response.json();
    } catch (error) {
      throw error;
    }
  },

  isAuthenticated: () => {
    return !!localStorage.getItem("vitpay_data");
  },
};

export default authService;
