import { useState, useEffect } from "react";
// import fetchDashboardData from '../../services/fetchDashboardData'; // Adjust path as per your structure
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";

const useUsers = () => {
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/defaulters/list`);

  const [userData, setUserData] = useState(null);
  //   const [loading, setLoading] = useState(true);
  //   const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setUserData(data);

      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);

  return { userData, loading, error };
};

export default useUsers;
