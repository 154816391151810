// src/components/admin/Loans/index.js
import React, { useContext } from "react";
import styles from "./index.module.css";
import CustomTable from "../../common/customTable";
import useRoles from "./useRoles";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./../../../contexts/authContext";

const Roles = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { roles, loading } = useRoles();
  const handleCreateRole = () => {
    navigate("/roles/create");
  };

  const headCells = [
    {
      field: "id",
      numeric: false,
      disablePadding: false,
      headerName: "SR.No",
      // type: "number",
      flex: 1,
    },
    {
      field: "createdAt",
      numeric: false,
      disablePadding: false,
      headerName: "Date",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
      flex: 1,
    },
    {
      field: "name",
      numeric: false,
      disablePadding: false,
      headerName: "Name",
      type: "string",
      flex: 1,
    },
  ];

  return (
    <section className={styles.roles}>
      {!loading ? (
        <>
          {roles && (
            <div className={styles.table}>
              <CustomTable
                headCells={headCells}
                rows={roles?.roles || []}
                type={`Roles`}
                showButton={user?.admin?.permissions?.permissions?.createRole}
                handleButton={handleCreateRole}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton width={"90%"} height={"90%"} variant="rounded" />
        </>
      )}
    </section>
  );
};

export default Roles;
