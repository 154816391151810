import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.css";
import CustomSelect from "../../common/select";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import useCreate from "./useCreate";
import { Skeleton } from "@mui/material";
import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
export default function CreateAdmin() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      if (!user?.admin?.permissions?.permissions?.createAdmin) {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);
  const { roles, loading, handleCreate, submitLoading } = useCreate();
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    title: "Mr. ",
    firstName: "",
    surName: "",
    phoneNumber: "",
    userName: "",
    email: "",
    role: "",
  });
  const handleChange = (field) => (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: event?.target?.value || event,
    }));
  };
  useEffect(() => {
    const dataWithIndex = roles?.roles?.map((item, index) => ({
      value: item.id,
      label: item?.name,
    }));
    setData(dataWithIndex);
  }, [roles]);

  return (
    <section className={styles.apply}>
      <h3>Create Admin</h3>
      {loading ? (
        <div className={styles.loading}>
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
          <Skeleton variant="rounded" width={"30%"} height={50} />
        </div>
      ) : (
        <div className={styles.form}>
          <div className={styles.form__inner}>
            <div className={styles.component}>
              <CustomInput
                label={"First Name"}
                type="text"
                className={styles.input}
                value={formData.firstName}
                onChange={(e) => {
                  handleChange("firstName")(e.target.value);
                }}
              />
              <CustomInput
                label={"Last Name"}
                type="text"
                className={styles.input}
                value={formData.surName}
                onChange={(e) => {
                  handleChange("surName")(e.target.value);
                }}
              />
              <CustomInput
                label={"Phone Number"}
                className={styles.input}
                value={formData.phoneNumber}
                onChange={(e) => {
                  handleChange("phoneNumber")(e.target.value);
                }}
              />
              <CustomInput
                label={"Email"}
                type="email"
                className={styles.input}
                value={formData.email}
                onChange={(e) => {
                  handleChange("email")(e.target.value);
                }}
              />

              <CustomInput
                label={"Username"}
                type="text"
                className={styles.input}
                value={formData.userName}
                onChange={(e) => {
                  handleChange("userName")(e.target.value);
                }}
              />
              <CustomSelect
                className={styles.input}
                options={data}
                label={"Role"}
                value={formData.role}
                onChange={(e) => {
                  handleChange("role")(e);
                }}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <Button
              className={styles.submit}
              onClick={() => {
                handleCreate(formData);
              }}
              loading={submitLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </section>
  );
}
