import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import Logo from "./../../../images/Logo.svg";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import CustomSelect from "../../common/select";
import CountrySelect from "../../common/countrySelect";
import Chip from "@mui/material/Chip";
import useUser from "./useUser";
import { Skeleton } from "@mui/material";
import CustomPieChart from "../../common/pieChart";
import { styled } from "@mui/material/styles";
import CustomTable from "../../common/customTable";
import CustomLineChart from "../../common/lineChart";
import { toTitleCase } from "../../../utils/titleCase";
import CustomBarChart from "../../common/barChart";
import ActionModal from "./actionModal";
import ResponsiveDatePicker from "../../common/dateTimePicker";
import {
  validateFirstName,
  validateSurName,
  validatePhoneNumber,
  validateEmail,
  validateResidentialAddressNumber,
  validateStreetName,
  validateResidentialArea,
  validateBankName,
  validateAccountNumber,
  validateUser,
} from "./validateUser";
import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
const User = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      if (!user?.admin?.permissions?.permissions?.viewUser) {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);
  const {
    userData,
    loading,
    handleUpdate,
    submitLoading,
    handleDefaulter,
    popupLoading,
  } = useUser();
  const [actionModal, setActionModal] = useState(false);

  const [remark, setRemark] = useState("");
  const [chartsData, setChartsData] = useState(null);
  const [optionType, setOptionType] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    surName: "Doe",
    phoneNumber: "+123456789",
    password: "",
    kycStatus: "active",
    country: "PK",
    NRCNumber: "ZN123456",
    countryCode: "PK",
    isVerified: true,
    status: "active",
    pin: "1234",
    otp: "1234",
    otpExpiresAt: "",
    createdAt: "",
    kycDocuments: [
      {
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/vitpay-54107.appspot.com/o/kyc%2Fundefined%2Fselfie.jpg?alt=media&token=f2219678-6948-4e7f-ba76-2eca0365da1d",

        name: "selfie",
      },
      {
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/vitpay-54107.appspot.com/o/kyc%2Fundefined%2Ffront.jpg?alt=media&token=e6ad4fe6-4038-4926-b57b-f610df1bbf1c",

        name: "front",
      },
      {
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/vitpay-54107.appspot.com/o/kyc%2Fundefined%2Fback.jpg?alt=media&token=af661634-b201-42af-aa60-2bdbf3e21791",

        name: "back",
      },
    ],
  });
  useEffect(() => {
    const validationErrors = validateUser(formData);
    // setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [formData]);
  const mergeMonthlyData = (allMonths, monthlyLoan) => {
    return allMonths.map((month) => {
      const found = monthlyLoan?.find((item) => item.month === month._id);
      return {
        _id: month._id,
        name: month.name,
        count: found ? found.count : 0,
      };
    });
  };

  const mergeStatusData = (allStatuses, statusTrends) => {
    return allStatuses.map((status) => {
      const found = statusTrends?.find((item) => item.status === status);
      return { _id: status, count: found ? found.count : 0 };
    });
  };
  useEffect(() => {
    setFormData({
      firstName: userData?.user?.firstName || "",
      surName: userData?.user?.surName || "",
      phoneNumber: userData?.user?.phoneNumber || "",
      accountNumber: userData?.user?.accountNumber || "",
      bankName: userData?.user?.bankName || "",
      dateOfBirth: userData?.user?.dateOfBirth || "",
      email: userData?.user?.email || "",
      userId: userData?.user?.userId || "",
      gender: userData?.user?.gender || "",
      status: userData?.user?.status || "",
      nationality: userData?.user?.nationality || "Zambia",
      NRCNumber: userData?.user?.NRCNumber || "",
      isVerified: userData?.user?.isVerified || "",
      isDefaulted: userData?.user?.isDefaulted || "",
      defaultedReason: userData?.user?.defaultedReason || "",

      loginAttempt: userData?.user?.loginAttempt || "",
      residentialAddressNumber: userData?.user?.residentialAddressNumber || "",
      residentialArea: userData?.user?.residentialArea || "",
      streetName: userData?.user?.streetName || "",
      title: userData?.user?.title || "",
      otp: userData?.user?.otp || "",
      otpExpiresAt: userData?.user?.otpExpiresAt,
      createdAt: userData?.user?.createdAt,
      loans: userData?.user?.loans,
      profilePhoto: userData?.user?.profilePhoto,
    });
    const allMonths = [
      { _id: 1, name: "January" },
      { _id: 2, name: "February" },
      { _id: 3, name: "March" },
      { _id: 4, name: "April" },
      { _id: 5, name: "May" },
      { _id: 6, name: "June" },
      { _id: 7, name: "July" },
      { _id: 8, name: "August" },
      { _id: 9, name: "September" },
      { _id: 10, name: "October" },
      { _id: 11, name: "November" },
      { _id: 12, name: "December" },
    ];

    const allStatuses = [
      "pending",
      "reviewed",
      "approved",
      "funded",
      "rejected",
      "paid",
      "overdue",
    ];
    const mergedMonthlyData = mergeMonthlyData(
      allMonths,
      userData?.monthlyLoan
    );
    const mergedStatusData = mergeStatusData(
      allStatuses,
      userData?.statusTrends
    );

    // Formatted data for charts
    const formattedLineChartData = {
      xData: mergedMonthlyData.map((item) => item.name),
      seriesData: mergedMonthlyData.map((item) => item.count),
      label: "Monthly Loans",
    };

    const formattedPieChartData = mergedStatusData.map((item) => ({
      value: item.count,
      label: item._id,
    }));

    const formattedBarChartData = {
      data: [
        {
          label: "Payments Timing",
          data: [
            userData?.credibility?.latePayments,
            userData?.credibility?.onTimePayments,
          ],
        },
      ],
      yAxis: ["Late Payments", "On-time Payments"],
    };
    setChartsData({
      monthlyLoan: formattedLineChartData,
      monthlyDisbursed: formattedPieChartData,
      monthlyProfit: formattedBarChartData,
    });
  }, [userData]);

  const [errors, setErrors] = useState({});

  const handleChange = (field) => (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: event?.target?.value || event,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: "8px",
    "&.MuiChip-outlined": {
      borderColor: "var(--border-focus)",
      color: "var(--border-focus)",
    },
  }));
  const getFullName = (value, row) => {
    return `${formData.firstName || ""} ${formData.surName || ""}`;
  };
  const headCells = [
    {
      field: "loanId",
      numeric: false,
      disablePadding: false,
      headerName: "ID",
      flex: 1,
    },
    {
      field: "createdAt",
      numeric: false,
      disablePadding: false,
      headerName: "Date",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
      flex: 1,
    },
    {
      field: "fullName",
      numeric: false,
      disablePadding: false,
      headerName: "Applied By",
      type: "string",
      valueGetter: getFullName,

      flex: 1,
    },
    {
      field: "isAdditionalLoan",
      numeric: true,
      disablePadding: false,
      headerName: "Additional Loan",
      type: "boolean",
      flex: 1,
    },
    {
      field: "loanAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Amount",
      flex: 1,
      valueGetter: (loanAmount) => {
        return (loanAmount = `K ${loanAmount}`);
      },
    },
    {
      field: "loanType",
      numeric: true,
      disablePadding: false,
      headerName: "Loan Type",
      flex: 1,
    },
    {
      field: "loanPeriod",
      numeric: true,
      disablePadding: false,
      headerName: "Loan Period",
      valueGetter: (loanPeriod) => {
        return (loanPeriod = `${loanPeriod} ${
          loanPeriod === 1 ? "Month" : "Months"
        }`);
      },
      flex: 1,
    },
    {
      field: "status",
      numeric: true,
      disablePadding: false,
      headerName: "Status",
      valueGetter: (status) => {
        return (status = `${toTitleCase(status)}`);
      },
      flex: 1,
    },
  ];

  const Verified = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];

  const Status = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Blocked", value: "blocked" },
    { label: "Deleted", value: "deleted" },
  ];
  const handleError = (field) => (event) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: event,
    }));
  };
  return (
    <section className={styles.user}>
      {loading ? (
        <div className={styles.form__inner}>
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
        </div>
      ) : (
        <>
          <div className={styles.user__details}>
            <div className={styles.user__name}>
              <img alt="" src={userData?.user?.profilePhoto || Logo} />
              <h3>
                {userData?.user?.firstName} {userData?.user?.surName}
              </h3>
            </div>
            {user?.admin?.permissions?.permissions?.handleDefaulter && (
              <div className={styles.user__details__inner}>
                <Button
                  children={
                    userData?.user?.isDefaulted
                      ? "Remove from Defaulter"
                      : "Add to Defaulter"
                  }
                  onClick={() => {
                    if (userData?.user?.isDefaulted) {
                      handleDefaulter();
                    } else {
                      setActionModal(true);
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles.form} onSubmit={handleSubmit}>
            <h1>User Details</h1>
            <div className={styles.form__inner}>
              <CustomInput
                placeholder="User Id"
                value={formData.userId}
                type="text"
                label="User ID"
                disabled
                error={errors.firstName}
                className={styles.input}
              />
              <CustomInput
                placeholder="First Name"
                value={formData.firstName}
                type="text"
                label="First Name"
                onChange={(event) => {
                  handleError("firstName")(
                    validateFirstName(event.target.value)
                  );

                  handleChange("firstName")(event.target.value);
                }}
                error={errors.firstName}
                className={styles.input}
              />
              <CustomInput
                placeholder="Last Name"
                value={formData.surName}
                type="text"
                label="Last Name"
                onChange={(event) => {
                  handleError("surName")(validateSurName(event.target.value));
                  handleChange("surName")(event?.target?.value);
                }}
                error={errors.surName}
                className={styles.input}
              />
              <CustomInput
                placeholder="Gender"
                value={formData.gender}
                type="text"
                label="Gender"
                disabled
                error={errors.gender}
                className={styles.input}
              />
              <CustomInput
                placeholder="Phone"
                value={formData.phoneNumber}
                type="tel"
                label="Phone"
                onChange={(event) => {
                  handleError("phoneNumber")(
                    validatePhoneNumber(event.target.value)
                  );
                  handleChange("phoneNumber")(event?.target?.value);
                }}
                className={styles.input}
                error={errors.phoneNumber}
              />
              <CustomInput
                placeholder="Email"
                value={formData.email}
                type="string"
                label="Email"
                onChange={(event) => {
                  handleError("email")(validateEmail(event.target.value));
                  handleChange("email")(event?.target?.value);
                }}
                className={styles.input}
                error={errors.email}
              />
              <CustomSelect
                label="Status"
                options={Status}
                value={formData.status}
                className={styles.input}
                onChange={(event) => {
                  handleChange("status")(event[0]);
                }}
              />

              <CustomInput
                placeholder="ID Number/Passport Number"
                value={formData.NRCNumber}
                type="text"
                label="ID Number/Passport Number"
                disabled
                className={styles.input}
                error={errors.NRCNumber}
              />

              <CustomSelect
                label="Verified"
                options={Verified}
                value={formData.isVerified}
                className={styles.input}
                onChange={handleChange("isVerified")}
              />
              <CustomSelect
                label="Defaulted?"
                options={Verified}
                value={formData.isDefaulted}
                disabled
                className={styles.input}
                onChange={handleChange("isVerified")}
              />
              {formData?.isDefaulted && (
                <CustomInput
                  label="Defaulted Reason?"
                  value={formData.defaultedReason}
                  disabled
                  className={styles.input}
                />
              )}
              <CustomInput
                placeholder="OTP"
                value={formData.otp}
                type="password"
                label="OTP"
                disabled
                onChange={handleChange("otp")}
                className={styles.input}
                error={errors.otp}
              />
              <CustomInput
                placeholder="Account Number"
                value={formData.accountNumber}
                type="text"
                label="Account Number"
                onChange={(event) => {
                  handleError("accountNumber")(
                    validateAccountNumber(event.target.value)
                  );
                  handleChange("accountNumber")(event?.target?.value);
                }}
                className={styles.input}
                error={errors.accountNumber}
              />
              <CustomInput
                placeholder="Bank Name"
                value={formData.bankName}
                type="text"
                label="Bank Name"
                onChange={(event) => {
                  handleError("bankName")(validateBankName(event.target.value));
                  handleChange("bankName")(event?.target?.value);
                }}
                className={styles.input}
                error={errors.bankName}
              />
              <CustomInput
                placeholder="Residential Address Number"
                value={formData.residentialAddressNumber}
                type="text"
                label="Residential Address Number"
                onChange={(event) => {
                  handleError("residentialAddressNumber")(
                    validateResidentialAddressNumber(event.target.value)
                  );
                  handleChange("residentialAddressNumber")(
                    event?.target?.value
                  );
                }}
                className={styles.input}
                error={errors.residentialAddressNumber}
              />
              <CustomInput
                placeholder="Residential Area"
                value={formData.residentialArea}
                type="text"
                label="Residential Area"
                onChange={(event) => {
                  handleError("residentialArea")(
                    validateResidentialArea(event.target.value)
                  );
                  handleChange("residentialArea")(event?.target?.value);
                }}
                className={styles.input}
                error={errors.residentialArea}
              />
              <CustomInput
                placeholder="Street Name"
                value={formData.streetName}
                type="text"
                label="Street Name"
                onChange={(event) => {
                  handleError("streetName")(
                    validateStreetName(event.target.value)
                  );
                  handleChange("streetName")(event?.target?.value);
                }}
                className={styles.input}
                error={errors.streetName}
              />
              <CountrySelect
                value={formData.nationality}
                label="Nationality"
                className={styles.input}
                type="Country"
                disabled
              />
              <ResponsiveDatePicker
                placeholder="OTP Expiration"
                defaultValue={formData.otpExpiresAt}
                type="datetime-local"
                label="OTP Expiration"
                onChange={handleChange("otpExpiresAt")}
                disabled
                width={"30%"}
                error={errors.otpExpiresAt}
              />
              <ResponsiveDatePicker
                placeholder="Date of Birth"
                defaultValue={formData.dateOfBirth}
                type="datetime-local"
                label="Date of Birth"
                onChange={handleChange("dateOfBirth")}
                disabled
                width={"30%"}
                error={errors.dateOfBirth}
              />
              <ResponsiveDatePicker
                placeholder="Joined At"
                defaultValue={formData.createdAt}
                type="datetime-local"
                label="Joined At"
                disabled
                onChange={handleChange("createdAt")}
                width={"30%"}
                error={errors.createdAt}
              />
            </div>
            {user?.admin?.permissions?.permissions?.updateUser && (
              <div className={styles.form__buttons}>
                <Button
                  type="submit"
                  className={styles.submit}
                  onClick={() => {
                    handleUpdate(formData);
                  }}
                  disabled={submitDisabled}
                  loading={submitLoading}
                >
                  Submit
                </Button>
                <Button
                  type="cancel"
                  className={`${styles.submit} ${styles.cancel}`}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>

          <div className={styles.options}>
            <div className={styles.options__inner}>
              <StyledChip
                label="Loans"
                onClick={() => {
                  setOptionType(0);
                }}
                variant={optionType === 0 ? "outlined" : ""}
              />
              <StyledChip
                label="Trends"
                onClick={() => {
                  setOptionType(1);
                }}
                variant={optionType === 1 ? "outlined" : ""}
              />
              {/* <StyledChip label="Settings" onClick={() => {}} /> */}
            </div>
          </div>
          <div className={styles.table}>
            {/* <ViewsTable rows={rows} onView={handleView} /> */}
            {optionType === 0 && (
              <CustomTable
                headCells={headCells}
                rows={formData?.loans || []}
                type={`Loans`}
              />
            )}
            {optionType === 1 && (
              <>
                <CustomLineChart
                  xData={chartsData.monthlyLoan?.xData}
                  seriesData={chartsData.monthlyLoan?.seriesData}
                  label={chartsData.monthlyLoan?.label}
                />
                <CustomPieChart data={chartsData?.monthlyDisbursed} />
                <CustomBarChart
                  data={chartsData?.monthlyProfit?.data}
                  yAxis={chartsData?.monthlyProfit?.yAxis}
                />
              </>
            )}
          </div>
        </>
      )}
      <ActionModal
        open={actionModal}
        setOpen={setActionModal}
        name={"Defaulter"}
        loading={popupLoading}
        onClick={() => {
          handleDefaulter(remark);
        }}
        input={remark}
        setInput={setRemark}
        error={errors.attachment}
      />
    </section>
  );
};

export default User;
