// src/components/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.css";
import Logo from "./../../../images/Logo.svg";
import Button from "../../common/button";
import CustomInput from "../../common/customInput";
import CheckBox from "../../common/checkbox";
// import { useNotification } from "../../../contexts/notificationContext";
import { useAuth } from "../../../contexts/authContext";
import useErrorHandler from "../../../hooks/useErrorHandler";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);
  // const { showNotification } = useNotification();
  const { login } = useAuth();
  const handleError = useErrorHandler();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const data = await login({ userName: email, password });
      if (data.admin.loginAttempt === 1) {
        navigate(`/verify?email=${data.admin.email}`);
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      setLoading(false);
      handleError(error, error.message);
    }
  };
  const handleForget = () => {
    navigate("/forget");
  };
  return (
    <section className={styles.login}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={Logo} alt="Vitpay" />
        </div>
        <div className={styles.form}>
          <h3>Login</h3>
          <div className={styles.form__div}>
            <CustomInput
              type="text"
              // placeholder="Username"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label={"Username"}
              className={styles.input}
            />
            <CustomInput
              type="password"
              // placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              label={"Password"}
              className={styles.input}
            />
            <CheckBox
              color={"var(--button-primary)"}
              checked={checked}
              onChange={() => {
                setChecked(!checked);
              }}
              label={"Remember Me"}
            />
            <Button
              children={"Login"}
              onClick={handleSubmit}
              className={styles.primary}
              loading={loading}
            />
            <p>
              Forget Password?{" "}
              <span onClick={handleForget}>Click Here to Reset!</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
