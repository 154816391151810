import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import usePostData from "../../../hooks/usePostData";

const useEmail = () => {
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/admins`);
  const {
    data: usersData,
    loading: roleLoading,
    error: roleError,
  } = useFetchData(`/users`);
  const { postData } = usePostData();

  const [admins, setAdmins] = useState(null);
  const [users, setUsers] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setAdmins(data);
      setUsers(usersData);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error, usersData, roleError, roleLoading]);
  const handleSend = async (
    type,
    users,
    emailSubject,
    emailBody,
    smsBody,
    sendMethod
  ) => {
    try {
      setSubmitLoading(true);
      const result = await postData(`/email`, {
        type,
        users,
        emailSubject,
        emailBody,
        smsBody,
        sendMethod,
      });
      handleError(result, result.msg, 'success')
      setSubmitLoading(false);
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);
      return;
    }
  };
  return { admins, loading, error, users, handleSend, submitLoading };
};

export default useEmail;
