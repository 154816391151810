import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import ThemeContextProvider from "./contexts/themeContext";
import NotificationWrapper from "./contexts/notificationContext";
import { AuthProvider } from "./contexts/authContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeContextProvider>
        <NotificationWrapper>
          <App />
        </NotificationWrapper>
      </ThemeContextProvider>
    </AuthProvider>
  </React.StrictMode>
);


