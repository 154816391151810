import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { useParams } from "react-router-dom";
import usePostData from "../../../hooks/usePostData";
const useLoan = () => {
  const { id } = useParams();
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/loan/${id}`);
  const { data: products } = useFetchData(`/products`);

  const { data: response, error: responseError, postData } = usePostData();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  const [loanData, setLoanData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const reportResult = useFetchData(`/generate/pdf/${id}`);
  useEffect(() => {
    const fetchData = async () => {
      setLoanData(data);
      setProductData(products);
      setReportData(reportResult?.data?.details);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error, products, reportResult]);

  const handleDelete = async () => {
    setDeleteLoading(true);
    await postData(`/loans/delete`, { id: id });
    if (!response) {
      if (responseError) {
        handleError(responseError, responseError.message);
      } else {
        handleError("Something went wrong");
      }
      setDeleteLoading(false);
      return;
    }
    setDeleteLoading(false);
    window.location.reload();
  };
  const handleUpdate = async (formData) => {
    try {
      setSaveChanges(true);
      const result = await postData(`/loan/update`, {
        id: id,
        loanType: formData?.loanType,
        addressDuration: formData?.addressDuration,
        netMonthlyIncome: formData?.netMonthlyIncome,
        employmentStatus: formData?.employmentStatus,
        publicService: formData?.publicService,
        employer: formData?.employer,
        employeeNumber: formData?.employeeNumber,
      });
      handleError(result, result.message, "success");
      setSaveChanges(false);
      window.location.reload();
    } catch (error) {
      setSaveChanges(false);
      handleError(error, error.message);
    }
  };
  const handleUpdateStatus = async (status, remark, attachment, file) => {
    try {
      setPopupLoading(true);
      const result = await postData(
        `${status === "Reject" ? "/loan/reject" : "/loan/accept"}`,
        {
          loanId: id,
          status,
          remarks: remark,
          isAttachment: attachment?.extension ? true : false,
          attachment,
        }
      );

      if (result.url) {
        const resaddress = await fetch(file.src);
        const blobaddress = await resaddress.blob();
        const addressUpload = await fetch(result.url, {
          method: "PUT",
          body: blobaddress,
        });
        if (addressUpload) {
        }
      }

      setPopupLoading(false);
      window.location.reload();
    } catch (err) {
      setPopupLoading(false);
      handleError(err, err.message || "Something went wrong");
    }
  };
  // useEffect(() => {
  //   const handleReport = async (req, res) => {
  //     try {
  //       const result = await postData(`/generate/pdf/${id}`);
  //       setReportData(result?.details);
  //     } catch (error) {
  //       handleError(error, error.message);
  //       setPopupLoading(false);
  //       return;
  //     }
  //   };
  //   handleReport();
  // }, [handleError, id, postData]);

  const handlePayInstallment = async (amount) => {
    try {
      setPopupLoading(true);
      const result = await postData("/loan/pay", {
        loanId: id,
        amount: amount,
        amountGiven: amount > 0 ? true : false,
      });
      if (result) {
      }
      window.location.reload();
    } catch (err) {
      setPopupLoading(false);
      handleError(err, err.message || "Something went wrong");
    }
  };
  const handleRequest = async (remarks, type) => {
    try {
      setPopupLoading(true);
      const result = await postData("/loan/request", {
        loanId: id,
        remark: remarks,
        type: type,
      });
      if (result) {
      }
      window.location.reload();
    } catch (err) {
      setPopupLoading(false);
      handleError(err, err.message || "Something went wrong");
    }
  };
  const handleExtend = async (month) => {
    try {
      setPopupLoading(true);
      const result = await postData("/loan/extend", {
        loanId: id,
        extensionPeriod: parseInt(month),
      });
      if (result) {
      }
      window.location.reload();
    } catch (err) {
      setPopupLoading(false);
      handleError(err, err.message || "Something went wrong");
    }
  };
  const checkCRB = async (user) => {
    try {
      setPopupLoading(true);

      const result = await postData("/crb/check", {
        user_id: user,
        loan_id: id,
      });
      if (result) {
      }
      window.location.reload();
    } catch (err) {
      setPopupLoading(false);
      handleError(err, err.message || "Something went wrong");
    }
  };
  const handleFund = async (
    fundType,
    phoneNumber,
    serviceCode,
    status,
    remark,
    attachment,
    file
  ) => {
    try {
      setPopupLoading(true);
      let result;
      if (fundType === "Manual") {
        result = await postData("/loan/accept", {
          loanId: id,
          status,
          remarks: remark,
          isAttachment: attachment?.extension ? true : false,
          attachment,
        });
      } else if (fundType === "Tingg") {
        result = await postData("/tingg/disbursement", {
          loanId: id,
          phone_number: phoneNumber,
          service_code: serviceCode,
        });
      }
      setPopupLoading(false);
      if (result) {
        if (result.message === "Success") {
          window.location.reload();
        } else {
          window.location.reload();
        }
      }
    } catch (err) {
      setPopupLoading(false);
      handleError(err, err.message || "Something went wrong");
    }
  };
  return {
    loanData,
    loading,
    error,
    handleDelete,
    deleteLoading,
    handleUpdate,
    saveChanges,
    handleUpdateStatus,
    popupLoading,
    handlePayInstallment,
    handleRequest,
    handleExtend,
    productData,
    checkCRB,
    reportData,
    handleFund,
  };
};

export default useLoan;
