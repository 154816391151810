import { useState } from "react";
import usePostData from "../../../hooks/usePostData";
import useErrorHandler from "../../../hooks/useErrorHandler";

const useForget = () => {
  //   const { data, loading, error } = useFetchData("/user");
  const handleError = useErrorHandler();
  const { postData } = usePostData();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  //   const [profile, setProfile] = useState(null);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       setProfile(data);
  //       if (error) {
  //         handleError(error, error.message);
  //       }
  //     };

  //     fetchData();
  //   }, [data, handleError, error]);
  const handleSubmitEmail = async (email) => {
    try {
      setLoading(true);
      const formData = {
        email: email,
      };
      await postData("/user/password/forget", formData);
      // Handle success
      setLoading(false);
      setIsEmailSent(true);
      // Handle error
    } catch (error) {
      handleError(error, error.message);
    }
  };
  return { isEmailSent, handleSubmitEmail, loading };
};

export default useForget;
