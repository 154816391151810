// src/hooks/useFetchData.js
import { useState, useEffect } from "react";
import apiClient from "../services/api";
import { useAuth } from "../contexts/authContext";
const useFetchData = (endpoint) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiClient.get(endpoint, token);
        setData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [endpoint, token]);

  return { data, loading, error };
};

export default useFetchData;
