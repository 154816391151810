import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { useParams } from "react-router-dom";
import usePostData from "../../../hooks/usePostData";

const useAdmin = () => {
  const { id } = useParams();
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/admin/${id}`);
  const {
    data: roleData,
    loading: roleLoading,
    error: roleError,
  } = useFetchData(`/roles`);
  const { postData } = usePostData();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [roles, setRoles] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setUserData(data);
      setRoles(roleData);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error, roleData, roleError, roleLoading]);
  const handleDelete = async () => {
    try {
       await postData(`/admins/delete`, { id: id });
    } catch (error) {
      handleError(error, error.message);
      return;
    }
  };
  const handleUpdate = async (user) => {
    try {
      setSubmitLoading(true);
      await postData(`/admin/update`, { id: id, ...user });
      window.location.reload();
      setSubmitLoading(false);
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);
      return;
    }
  };
  return {
    userData,
    loading,
    error,
    roles,
    handleDelete,
    handleUpdate,
    submitLoading,
  };
};

export default useAdmin;
