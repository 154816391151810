import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import CustomSelect from "../../common/select";
import useCompany from "./useCompany";
import { Skeleton } from "@mui/material";
import ResponsiveDateTimePicker from "../../common/datePicker";

import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
const Company = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      if (!user?.admin?.permissions?.permissions?.viewUser) {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);
  const { companyData, loading, handleUpdate, submitLoading } = useCompany();
  const [formData, setFormData] = useState({
    company_name: "",
    voucher_code: "",
    interest_rate: "+123456789",
    createdAt: "Doe",
    start_date: "active",
    end_date: "PK",
    status: "ZN123456",
  });

  useEffect(() => {
    setFormData({
      company_name: companyData?.company_name || "",
      createdAt: companyData?.createdAt || "",
      interest_rate: companyData?.interest_rate || "",
      voucher_code: companyData?.voucher_code || "",
      start_date: companyData?.start_date || new Date(),
      end_date: companyData?.end_date || new Date(),
      status: companyData?.status || "",
    });
  }, [companyData]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const Status = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Expired", value: "expired" },
  ];

  return (
    <section className={styles.user}>
      {loading ? (
        <div className={styles.form__inner}>
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
        </div>
      ) : (
        <>
          <div className={styles.user__details}>
            <div className={styles.user__name}>
              <h3>{companyData?.name}</h3>
            </div>
          </div>
          <div className={styles.form} onSubmit={handleSubmit}>
            <h1>User Details</h1>
            <div className={styles.form__inner}>
              <CustomInput
                placeholder="Name"
                value={formData.company_name}
                type="text"
                label="Name"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: e.target.value,
                  });
                }}
                className={styles.input}
              />
              <CustomInput
                placeholder="Voucher Code"
                value={formData.voucher_code}
                type="text"
                label="Voucher Code"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    voucher_code: e.target.value,
                  });
                }}
                className={styles.input}
              />
              <CustomInput
                placeholder="Interest Rate"
                value={formData.interest_rate}
                type="text"
                label="Interest Rate"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    interest_rate: e.target.value,
                  });
                }}
                className={styles.input}
              />
              <CustomSelect
                label="Status"
                options={Status}
                value={formData.status}
                className={styles.input}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    status: e[0],
                  });
                }}
              />
              <ResponsiveDateTimePicker
                placeholder="Start Date"
                value={formData.start_date}
                label="Start Date"
                onChange={(value) => {
                  const date = new Date(value);
                  const formattedDate = new Date(
                    Date.UTC(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate()
                    )
                  );
                  setFormData({
                    ...formData,
                    start_date: formattedDate,
                  });
                }}
                width={"30%"}
              />
              <ResponsiveDateTimePicker
                placeholder="End Date"
                value={formData.end_date}
                label="End Date"
                onChange={(value) => {
                  const date = new Date(value);
                  const formattedDate = new Date(
                    Date.UTC(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate()
                    )
                  );
                  setFormData({
                    ...formData,
                    end_date: formattedDate,
                  });
                }}
                width={"30%"}
              />
              <ResponsiveDateTimePicker
                placeholder="Created At"
                defaultValue={formData.createdAt}
                type="datetime-local"
                label="Created At"
                disabled
                onChange={() => {}}
                width={"30%"}
              />
            </div>
            {user?.admin?.permissions?.permissions?.updateUser && (
              <div className={styles.form__buttons}>
                <Button
                  type="submit"
                  className={styles.submit}
                  onClick={() => {
                    handleUpdate(formData);
                  }}
                  loading={submitLoading}
                >
                  Submit
                </Button>
                <Button
                  type="cancel"
                  className={`${styles.submit} ${styles.cancel}`}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default Company;
