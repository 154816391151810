import React from "react";
import Box from "@mui/material/Box";
import { BarChart } from "@mui/x-charts/BarChart";
import PropTypes from "prop-types";

const CustomBarChart = ({
  data,
  height = 300,
  initialSeriesNb = 1,
  initialItemNb = 12,
  yAxis,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <BarChart
        height={height}
        series={data}
        skipAnimation={false}
        xAxis={[{ scaleType: "band", data: yAxis }]}
        // leftAxis={[0,0,0,0,0,0,0,0,0,0,0]}
        // yAxis={[{scaleType: "linear", data:data}]}
      />
    </Box>
  );
};

CustomBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
      highlightScope: PropTypes.shape({
        highlighted: PropTypes.string,
        faded: PropTypes.string,
      }),
    })
  ).isRequired,
  height: PropTypes.number,
  initialSeriesNb: PropTypes.number,
  initialItemNb: PropTypes.number,
  skipAnimationDefault: PropTypes.bool,
};

export default CustomBarChart;
