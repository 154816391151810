import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import Logo from "./../../../images/Logo.svg";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import CustomSelect from "../../common/select";
import Chip from "@mui/material/Chip";
import useAdmin from "./useAdmin";
import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ResponsiveDatePicker from "../../common/dateTimePicker";
import CustomRadioGroup from "./cutomRadioGroup";
import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import {
  validateFirstName,
  validateSurName,
  validatePhoneNumber,
  validateEmail,
  validateUser,
} from "./validateAdmin";
const Admin = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      if (!user?.admin?.permissions?.permissions?.viewAdmin) {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);
  const {
    userData,
    loading,
    roles,
    handleDelete,
    handleUpdate,
    submitLoading,
  } = useAdmin();
  const [rolesSelect, setRolesSelect] = useState(null);
  const [permissionsData, setPermissionsData] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    surName: "Doe",
    phoneNumber: "+123456789",
    kycStatus: "active",
    country: "PK",
    NRCNumber: "ZN123456",
    countryCode: "PK",
    isVerified: true,
    status: "active",
    pin: "1234",
    otp: "1234",
    otpExpiresAt: "",
    createdAt: "",
  });

  useEffect(() => {
    setFormData({
      firstName: userData?.user?.firstName || "",
      surName: userData?.user?.surName || "",
      phoneNumber: userData?.user?.phoneNumber || "",
      accountNumber: userData?.user?.accountNumber || "",
      bankName: userData?.user?.bankName || "",
      dateOfBirth: userData?.user?.dateOfBirth || "",
      gender: userData?.user?.gender || "",
      status: userData?.user?.status || "",
      country: userData?.user?.nationality || "Zambia",
      NRCNumber: userData?.user?.NRCNumber || "",
      isVerified: userData?.user?.isVerified || "",
      loginAttempt: userData?.user?.loginAttempt || "",
      residentialAddressNumber: userData?.user?.residentialAddressNumber || "",
      residentialArea: userData?.user?.residentialArea || "",
      streetName: userData?.user?.streetName || "",
      title: userData?.user?.title || "",
      otp: userData?.user?.otp || "",
      email: userData?.user?.email || "",
      otpExpiresAt: userData?.user?.otpExpiresAt,
      createdAt: userData?.user?.createdAt,
      loans: userData?.user?.loans,
      profilePhoto: userData?.user?.profilePhoto,
      role: userData?.user?.permissions.id || "",
      userName: userData?.user?.userName || "",
    });
    const rolesData = roles?.roles?.map((role) => {
      return {
        label: role.name,
        value: role.id,
      };
    });
    setRolesSelect(rolesData);
    setPermissionsData(userData?.user?.permissions?.permissions);
  }, [userData, roles]);

  const [errors, setErrors] = useState({});

  const handleChange = (field) => (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: event?.target?.value || event,
    }));
  };
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    const validationErrors = validateUser(formData);
    // setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [formData]);
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: "8px",
    "&.MuiChip-outlined": {
      borderColor: "var(--border-focus)",
      color: "var(--border-focus)",
    },
  }));

  const Verified = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];

  const Status = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Blocked", value: "blocked" },
    { label: "Deleted", value: "deleted" },
  ];
  const handleError = (field) => (event) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: event,
    }));
  };
  return (
    <section className={styles.user}>
      {loading ? (
        <div className={styles.form__inner}>
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
        </div>
      ) : (
        <>
          <div className={styles.user__details}>
            <div className={styles.user__name}>
              <img alt="" src={formData?.profilePhoto || Logo} />
              <h3>
                {formData.firstName} {formData.surName}
              </h3>
            </div>
            {user?.admin?.permissions?.permissions?.deleteAdmin && (
              <div className={styles.delete__button}>
                <Button children={"Delete"} onClick={handleDelete} />
              </div>
            )}
          </div>
          <div className={styles.form} onSubmit={handleSubmit}>
            <h1>User Details</h1>
            <div className={styles.form__inner}>
              <CustomInput
                placeholder="First Name"
                value={formData.firstName}
                type="text"
                label="First Name"
                onChange={(event) => {
                  handleError("firstName")(
                    validateFirstName(event.target.value)
                  );

                  handleChange("firstName")(event.target.value);
                }}
                error={errors.firstName}
                className={styles.input}
              />
              <CustomInput
                placeholder="Last Name"
                value={formData.surName}
                type="text"
                label="Last Name"
                onChange={(event) => {
                  handleError("surName")(validateSurName(event.target.value));
                  handleChange("surName")(event?.target?.value);
                }}
                error={errors.surName}
                className={styles.input}
              />
              <CustomInput
                placeholder="Phone"
                value={formData.phoneNumber}
                type="tel"
                label="Phone"
                onChange={(event) => {
                  handleError("phoneNumber")(
                    validatePhoneNumber(event.target.value)
                  );
                  handleChange("phoneNumber")(event?.target?.value);
                }}
                className={styles.input}
                error={errors.phoneNumber}
              />
              <CustomInput
                placeholder="Username"
                value={formData.userName}
                type="text"
                label="Username"
                disabled
                className={styles.input}
              />
              <CustomSelect
                label="Status"
                options={Status}
                value={formData.status}
                className={styles.input}
                onChange={(e) => {
                  handleChange("status")(e[0]);
                }}
              />
              <CustomSelect
                label="Role"
                options={rolesSelect}
                value={formData.role || []}
                className={styles.input}
                onChange={(e) => {
                  handleChange("role")(e[0]);
                }}
              />
              <CustomInput
                placeholder="email"
                value={formData.email}
                type="text"
                label="Email"
                onChange={(event) => {
                  handleError("email")(validateEmail(event.target.value));
                  handleChange("email")(event?.target?.value);
                }}
                className={styles.input}
                error={errors.email}
              />

              <CustomSelect
                label="Verified"
                options={Verified}
                value={formData.isVerified}
                className={styles.input}
                onChange={(e) => {
                  handleChange("isVerified")(e);
                }}
              />

              <CustomInput
                placeholder="OTP"
                value={formData.otp}
                type="password"
                label="OTP"
                disabled
                className={styles.input}
                error={errors.otp}
              />

              <ResponsiveDatePicker
                placeholder="OTP Expiration"
                defaultValue={new Date(formData.otpExpiresAt)?.toLocaleDateString("en-GB")} 
                type="datetime-local"
                label="OTP Expiration"
                onChange={handleChange("otpExpiresAt")}
                disabled
                width={"30%"}
                error={errors.otpExpiresAt}
              />

              <ResponsiveDatePicker
                placeholder="Joined At"
                defaultValue={new Date(formData.createdAt)?.toLocaleDateString("en-GB")}
                type="datetime-local"
                label="Joined At"
                disabled
                onChange={handleChange("createdAt")}
                width={"30%"}
                error={errors.createdAt}
              />
            </div>
            {user?.admin?.permissions?.permissions?.updateAdmin && (
              <div className={styles.form__buttons}>
                <Button
                  type="submit"
                  className={styles.submit}
                  onClick={() => {
                    handleUpdate(formData);
                  }}
                  disabled={submitDisabled}
                  loading={submitLoading}
                >
                  Submit
                </Button>
                <Button
                  type="cancel"
                  className={`${styles.submit} ${styles.cancel}`}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>

          <div className={styles.options}>
            <div className={styles.options__inner}>
              <StyledChip
                label="Permissions"
                onClick={() => {}}
                variant="outlined"
              />
            </div>
          </div>
          <div className={styles.table}>
            <div className={styles.radio__div}>
              <CustomRadioGroup
                label="Can Create Admins?"
                value={permissionsData?.createAdmin}
                selectedValue={permissionsData?.createAdmin}
              />
              <CustomRadioGroup
                label="Can Update Admins?"
                value={permissionsData?.updateAdmin}
                selectedValue={permissionsData?.updateAdmin}
              />
              <CustomRadioGroup
                label="Can Delete Admins?"
                value={permissionsData?.deleteAdmin}
                selectedValue={permissionsData?.deleteAdmin}
              />
              <CustomRadioGroup
                label="Can View Admins?"
                value={permissionsData?.viewAdmin}
                selectedValue={permissionsData?.viewAdmin}
              />
              <CustomRadioGroup
                label="Can Create Roles?"
                value={permissionsData?.createRole}
                selectedValue={permissionsData?.createRole}
              />
              <CustomRadioGroup
                label="Can Delete Roles?"
                value={permissionsData?.deleteRole}
                selectedValue={permissionsData?.deleteRole}
              />
              <CustomRadioGroup
                label="Can Email Admins?"
                value={permissionsData?.emailAdmin}
                selectedValue={permissionsData?.emailAdmin}
              />
              <CustomRadioGroup
                label="Can SMS Admins?"
                value={permissionsData?.smsAdmin}
                selectedValue={permissionsData?.smsAdmin}
              />
              <CustomRadioGroup
                label="Can Email Users?"
                value={permissionsData?.emailUsers}
                selectedValue={permissionsData?.emailUsers}
              />
              <CustomRadioGroup
                label="Can SMS Users?"
                value={permissionsData?.smsUsers}
                selectedValue={permissionsData?.smsUsers}
              />
              <CustomRadioGroup
                label="Can View Interest Rates?"
                value={permissionsData?.viewInterestRates}
                selectedValue={permissionsData?.viewInterestRates}
              />
              <CustomRadioGroup
                label="Can Update Interest Rates?"
                value={permissionsData?.updateInterestRates}
                selectedValue={permissionsData?.updateInterestRates}
              />
              <CustomRadioGroup
                label="Can View Users?"
                value={permissionsData?.viewUser}
                selectedValue={permissionsData?.viewUser}
              />
              <CustomRadioGroup
                label="Can Update Users?"
                value={permissionsData?.updateUser}
                selectedValue={permissionsData?.updateUser}
              />
              <CustomRadioGroup
                label="Can Handle Defaulters?"
                value={permissionsData?.handleDefaulter}
                selectedValue={permissionsData?.handleDefaulter}
              />
              <CustomRadioGroup
                label="Can View Defaulters?"
                value={permissionsData?.viewDefaulters}
                selectedValue={permissionsData?.viewDefaulters}
              />
              <CustomRadioGroup
                label="Can View Forms?"
                value={permissionsData?.viewForms}
                selectedValue={permissionsData?.viewForms}
              />
              <CustomRadioGroup
                label="Can Create Forms?"
                value={permissionsData?.createForm}
                selectedValue={permissionsData?.createForm}
              />
              <CustomRadioGroup
                label="Can Delete Forms?"
                value={permissionsData?.deleteForm}
                selectedValue={permissionsData?.deleteForm}
              />
              <CustomRadioGroup
                label="Can Update Forms?"
                value={permissionsData?.updateForm}
                selectedValue={permissionsData?.updateForm}
              />
              <CustomRadioGroup
                label="Can Apply For Loans?"
                value={permissionsData?.applyForLoan}
                selectedValue={permissionsData?.applyForLoan}
              />
              <CustomRadioGroup
                label="Can View Analytics?"
                value={permissionsData?.viewAnalytics}
                selectedValue={permissionsData?.viewAnalytics}
              />
              <CustomRadioGroup
                label="Can Generate Monthly Due Statements?"
                value={permissionsData?.generateMonthlyDueStatement}
                selectedValue={permissionsData?.generateMonthlyDueStatement}
              />
              <CustomRadioGroup
                label="Can Generate Statistics Statements?"
                value={permissionsData?.generateStatisticsStatement}
                selectedValue={permissionsData?.generateStatisticsStatement}
              />
              <CustomRadioGroup
                label="Can View Loans?"
                value={permissionsData?.viewLoan}
                selectedValue={permissionsData?.viewLoan}
              />
              <CustomRadioGroup
                label="Can Update Loans?"
                value={permissionsData?.updateLoan}
                selectedValue={permissionsData?.updateLoan}
              />
              <CustomRadioGroup
                label="Can Request Loan Items?"
                value={permissionsData?.requestLoanItem}
                selectedValue={permissionsData?.requestLoanItem}
              />
              <CustomRadioGroup
                label="Can Review Loans?"
                value={permissionsData?.reviewLoan}
                selectedValue={permissionsData?.reviewLoan}
              />
              <CustomRadioGroup
                label="Can Approve Loans?"
                value={permissionsData?.approveLoan}
                selectedValue={permissionsData?.approveLoan}
              />
              <CustomRadioGroup
                label="Can Fund Loans?"
                value={permissionsData?.fundLoan}
                selectedValue={permissionsData?.fundLoan}
              />
              <CustomRadioGroup
                label="Can Reject Loans?"
                value={permissionsData?.rejectLoan}
                selectedValue={permissionsData?.rejectLoan}
              />
              <CustomRadioGroup
                label="Can Pay Installments?"
                value={permissionsData?.payInstallment}
                selectedValue={permissionsData?.payInstallment}
              />
              <CustomRadioGroup
                label="Can Extend Loans?"
                value={permissionsData?.extendLoan}
                selectedValue={permissionsData?.extendLoan}
              />
              <CustomRadioGroup
                label="Can View Live Loan Reports?"
                value={permissionsData?.viewLiveLoanReport}
                selectedValue={permissionsData?.viewLiveLoanReport}
              />
              <CustomRadioGroup
                label="Can Generate Loan Reports?"
                value={permissionsData?.generateLoanReports}
                selectedValue={permissionsData?.generateLoanReports}
              />
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Admin;
