// src/components/admin/Loans/index.js
import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import CustomTable from "../../common/customTable";
import Button from "./../../common/button";
import { useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { useTheme } from "../../../contexts/themeContext";
import useLoans from "./useLoans";
import ResponsiveDateTimePicker from "../../common/datePicker";
import { toTitleCase } from "../../../utils/titleCase";
import { Skeleton } from "@mui/material";
import { AuthContext } from "./../../../contexts/authContext";
const Loans = () => {
  const { user } = useContext(AuthContext);

  const [reportOpen, setReportOpen] = useState(false);
  const { darkMode } = useTheme();
  const {
    loanData,
    loading,
    startDate,
    endDate,
    setEndDate,
    setStartDate,
    handleGenerateReport,
    responseLoading,
  } = useLoans();

  const handleOpen = () => {
    setReportOpen(true);
  };
  const handleClose = () => {
    setReportOpen(false);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  useEffect(() => {}, [loanData]);
  const headCells = [
    {
      field: "loanId",
      numeric: false,
      disablePadding: false,
      headerName: "ID",
      // type: "number",
      flex: 1,
    },
    {
      field: "createdAt",
      numeric: false,
      disablePadding: false,
      headerName: "Date",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
      flex: 1,
    },
    {
      field: "user",
      numeric: false,
      disablePadding: false,
      headerName: "Applied By",
      type: "string",
      valueGetter: (user) => {
        return (user = `${user.firstName} ${user.surName}`);
      },

      flex: 1,
    },
    {
      field: "isAdditionalLoan",
      numeric: true,
      disablePadding: false,
      headerName: "Additional Loan",
      type: "boolean",
      flex: 1,
    },
    {
      field: "loanAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Amount",
      flex: 1,
      valueGetter: (loanAmount) => {
        return (loanAmount = `K ${loanAmount}`);
      },
    },
    {
      field: "loanType",
      numeric: true,
      disablePadding: false,
      headerName: "Loan Type",
      flex: 1,
    },
    {
      field: "loanPeriod",
      numeric: true,
      disablePadding: false,
      headerName: "Loan Period",
      valueGetter: (loanPeriod) => {
        return (loanPeriod = `${loanPeriod} ${
          loanPeriod === 1 ? "Month" : "Months"
        }`);
      },
      flex: 1,
    },
    {
      field: "status",
      numeric: true,
      disablePadding: false,
      headerName: "Status",
      valueGetter: (status) => {
        return (status = `${toTitleCase(status)}`);
      },
      flex: 1,
    },
  ];

  return (
    <section className={styles.loans}>
      {!loading ? (
        <>
          {user?.admin?.permissions?.permissions?.generateLoanReports && (
            <div className={styles.report__header}>
              <Button children={"Generate Report"} onClick={handleOpen} />
            </div>
          )}
          {loanData && (
            <div className={styles.table}>
              <CustomTable
                headCells={headCells}
                rows={loanData?.loans}
                type={`Loans`}
                clickableRows={user?.admin?.permissions?.permissions?.viewLoan}
              />
            </div>
          )}
          <Modal
            open={reportOpen}
            data-theme={darkMode ? "dark" : "light"}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className={styles.modal}>
              <h2>Generate Report for {toTitleCase(type)} Loans</h2>
              <div className={styles.container}>
                <h3>Filter by Date</h3>
                <div className={styles.container__main}>
                  <div className={styles.inner_container}>
                    <p>Start Date</p>
                    <ResponsiveDateTimePicker
                      onChange={(value) => {
                        const date = new Date(value);
                        const formattedDate = new Date(
                          Date.UTC(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate()
                          )
                        );
                        setStartDate(formattedDate);
                      }}
                      value={startDate}
                    />
                  </div>
                  <div className={styles.inner_container}>
                    <p>End Date</p>
                    <ResponsiveDateTimePicker
                      onChange={(value) => {
                        const date = new Date(value);
                        const formattedDate = new Date(
                          Date.UTC(
                            date.getFullYear(),
                            date.getMonth(),
                            date.getDate()
                          )
                        );

                        setEndDate(formattedDate);
                      }}
                      value={endDate}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.button}>
                <Button
                  children={"Generate"}
                  onClick={async () => {
                    await handleGenerateReport();
                    handleClose();
                  }}
                  loading={responseLoading}
                />
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <>
          <Skeleton width={"90%"} height={"90%"} variant="rounded" />
        </>
      )}
    </section>
  );
};

export default Loans;
