import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import usePostData from "../../../hooks/usePostData";
const useCompanies = () => {
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/companies`);
  const [companiesData, setCompaniesData] = useState(null);
  const { loading: responseLoading, postData } = usePostData();

  useEffect(() => {
    const fetchData = async () => {
      setCompaniesData(data);

      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);
  const handleCompanyData = async (companyData) => {
    try {
      const inputData = {
        start_date: companyData.start_date,
        end_date: companyData.end_date,
        name: companyData.company_name,
        voucher_code: companyData.voucher_code,
        interest_rate: companyData.interest_rate,
      };

      const response = await postData("/company", inputData);
      if (response) {
        window.location.reload();
      }
    } catch (error) {
      handleError(error, error.message);
    }
  };
  return {
    companiesData,
    loading,
    error,
    responseLoading,
    handleCompanyData,
  };
};

export default useCompanies;
