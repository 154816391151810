import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { useParams } from "react-router-dom";
import usePostData from "../../../hooks/usePostData";
import { useNavigate } from "react-router-dom";
const useRole = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/roles/${id}`);
  const { postData } = usePostData();
  const [submitLoading, setSubmitLoading] = useState(null);
  const [role, setRole] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setRole(data);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);
  const handleDelete = async () => {
    try {
      await postData(`/role/delete`, { roleId: id });
      navigate("/roles");
    } catch (error) {
      handleError(error, error.message);
      return;
    }
  };
  const handleUpdate = async (updatedRole) => {
    try {
      setSubmitLoading(true);
      await postData(`/roles/update`, {
        id: id,
        ...updatedRole,
      });
      setSubmitLoading(false);
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);
      return;
    }
  };
  return { loading, error, role, handleDelete, handleUpdate, submitLoading };
};

export default useRole;
