// src/components/Login.js
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Logo from "./../../../images/Logo.svg";
import Button from "../../common/button";
import CustomInput from "../../common/customInput";
import useReset from "./useReset";
import { useSearchParams } from "react-router-dom";
import {
  validateOTP,
  validateNewPassword,
  validateConfirmPassword,
  validatePasswordChange,
} from "./validateReset";
const ResetPassword = () => {
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");

  const [formData, setFormData] = useState({
    otp: "",
    password: "",
    confirmPassword: "",
  });
  const {
    handleSubmitEmail,
    handleResendOTP,
    isOTPExpired,
    loading,
    otpLoading,
  } = useReset();
  const [disable, setDisable] = useState(false);
  const [errors, setErrors] = useState(false);
  const handleChange = (field) => (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: event?.target?.value || event,
    }));
  };
  const handleError = (field) => (event) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: event,
    }));
  };
  useEffect(() => {
    const validationErrors = validatePasswordChange(formData);
    // setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [formData]);
  return (
    <section className={styles.login}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={Logo} alt="Vitpay" />
        </div>
        <div className={styles.form}>
          <h3>Reset Password</h3>
          <div className={styles.form__div}>
            <div className={styles.otp}>
              <CustomInput
                type="text"
                placeholder="OTP"
                value={formData.otp}
                onChange={(event) => {
                  handleError("otp")(validateOTP(event.target.value));
                  handleChange("otp")(event.target.value);
                }}
                label={"OTP"}
                className={styles.input}
                error={errors.otp}
              />
              {isOTPExpired && (
                <Button
                  children={"OTP Expired? Resend OTP"}
                  onClick={() => {
                    handleResendOTP(email);
                  }}
                  className={styles.otpExpired}
                  loading={otpLoading}
                />
              )}
            </div>
            <CustomInput
              type="password"
              placeholder="New Password"
              value={formData.password}
              onChange={(event) => {
                handleError("password")(
                  validateNewPassword(event.target.value)
                );
                handleChange("password")(event.target.value);
              }}
              label={"New Password"}
              className={styles.input}
              error={errors.password}
            />
            <CustomInput
              type="password"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={(event) => {
                handleError("confirmPassword")(
                  validateConfirmPassword(
                    event.target.value,
                    formData?.password
                  )
                );

                handleChange("confirmPassword")(event.target.value);
              }}
              label={"Confirm Password"}
              className={styles.input}
              error={errors.confirmPassword}
            />
            <Button
              children={"Reset Password"}
              onClick={() => {
                handleSubmitEmail(formData, email);
              }}
              className={styles.primary}
              loading={loading}
              disabled={disable}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
