export const validateNewPassword = (value) => {
  if (!value) return "New Password is required.";
  if (value.length < 8)
    return "New Password must be at least 8 characters long.";
  // Add more criteria as needed (e.g., uppercase, lowercase, numbers, special characters)
  return null;
};

export const validateOTP = (value) => {
  if (!value) return "OTP is required.";
  return null;
};

export const validateConfirmPassword = (value, newPassword) => {
  if (!value) return "Confirm Password is required.";
  if (value !== newPassword)
    return "Confirm Password does not match New Password.";
  return null;
};

export const validatePasswordChange = (formData) => {
  const errors = {};

  errors.oldPassword = validateOTP(formData.otp);
  errors.password = validateNewPassword(formData.password);
  errors.confirmPassword = validateConfirmPassword(
    formData.confirmPassword,
    formData.password
  );

  Object.keys(errors).forEach((key) => {
    if (errors[key] === null) {
      delete errors[key];
    }
  });
  return errors;
};
