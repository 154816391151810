import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--border-color)",
    },
    "&:hover fieldset": {
      borderColor: "var(--border-color)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--border-focus)",
    },
    "&.Mui-disabled fieldset": {
      color: "var(--text-primary)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "grey",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    color: "var(--text-primary)",
    "-webkit-text-fill-color": "inherit",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--border-focus)",
  },
  "& .MuiInputLabel-root.MuiInputLabel-shrink": {
    color: "var(--border-focus)",
  },
  "& input:-webkit-autofill": {
    "-webkit-box-shadow": "0 0 0 1000px transparent inset",
    "-webkit-text-fill-color": theme.palette.text.primary,
  },
  "& input:-webkit-autofill:focus": {
    "-webkit-box-shadow": "0 0 0 1000px transparent inset",
    "-webkit-text-fill-color": theme.palette.text.primary,
  },
  "& input:-webkit-autofill:hover": {
    "-webkit-box-shadow": "0 0 0 1000px transparent inset",
    "-webkit-text-fill-color": theme.palette.text.primary,
  },
}));

const CustomInput = ({
  onChange,
  className,
  placeholder,
  type = "text",
  value,
  label,
  variant = "outlined",
  error = false,
  startAdornment,
  endAdornment,
  disabled,
  multiline,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Tooltip
      title={error ? error : ""}
      disableFocusListener
      disableTouchListener
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      arrow
      placement="top"
    >
      <StyledTextField
        onChange={onChange}
        className={className}
        placeholder={placeholder}
        type={type === "password" && !showPassword ? "password" : "text"}
        value={value}
        label={label}
        variant={variant}
        error={Boolean(error)}
        disabled={disabled}
        multiline={multiline}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: (
            <InputAdornment position="end">
              {type === "password" && (
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )}
              {endAdornment}
            </InputAdornment>
          ),
        }}
      />
    </Tooltip>
  );
};

CustomInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  helperText: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
};

export default CustomInput;
