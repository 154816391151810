import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

export default function CheckBox({ label, checked, onChange }) {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom:"0.5rem" }}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: "1.5rem", // Adjust icon size if needed
            color: "var(--button-primary)",
            padding: 0,
          },
        }}
      />
      <span style={{ color: "var(--text-primary)" }}>{label}</span>
    </div>
  );
}
