import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";

const useProfile = () => {
  const { data, loading, error } = useFetchData("/user");
  const handleError = useErrorHandler();

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setProfile(data);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);

  return { profile, loading, error };
};

export default useProfile;
