import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
const useRoles = () => {
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/roles`);

  const [roles, setRoles] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setRoles(data);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);

  return { loading, error, roles };
};

export default useRoles;
