// src/components/dataProcessing.js
export const processMonthlyDisbursed = (analyticsData) => {
  return {
    xData:
      analyticsData?.analytics?.monthlyDisbursedAmounts?.map(
        (data) => data.month
      ) || [],
    seriesData:
      analyticsData?.analytics?.monthlyDisbursedAmounts?.map(
        (data) => data.total
      ) || [],
  };
};

export const processOutstanding = (analyticsData) => {
  const totalOutstanding =
    analyticsData?.analytics?.outstandingPerMonthArray?.map(
      (item) => item.totalOutstanding
    ) || [];
  return [{ label: "Total Outstanding", data: totalOutstanding }];
};

export const processMonthlyProfit = (profitData) => {
  const data = profitData?.dailyProfits?.monthlyProfits || [];
  return [
    {
      label: "Processing Fee",
      data: data.map((item) => item.processingFee) || [],
    },
    {
      label: "Insurance Fee",
      data: data.map((item) => item.insuranceFee) || [],
    },
    { label: "Interest Fee", data: data.map((item) => item.interestFee) || [] },
    { label: "Penalty Fee", data: data.map((item) => item.penaltyFee) || [] },
    {
      label: "Total Profit",
      data:
        data.map(
          (item) =>
            item.processingFee +
            item.insuranceFee +
            item.interestFee +
            item.penaltyFee
        ) || [],
    },
  ];
};

export const processProfit = (profitData, period) => {
  const data = profitData?.dailyProfits[period] || [];
  return [
    {
      label: "Processing Fee",
      data: data.map((item) => item.processingFee) || [],
    },
    {
      label: "Insurance Fee",
      data: data.map((item) => item.insuranceFee) || [],
    },
    { label: "Interest Fee", data: data.map((item) => item.interestFee) || [] },
    { label: "Penalty Fee", data: data.map((item) => item.penaltyFee) || [] },
    {
      label: "Total Profit",
      data:
        data.map(
          (item) =>
            item.processingFee +
            item.insuranceFee +
            item.interestFee +
            item.penaltyFee
        ) || [],
    },
  ];
};

export const processUpcomingInstallments = (profitData, period) => {
  const data = profitData?.upcoming?.[`upcoming${period}Installments`] || [];
  return [
    {
      label: `Upcoming ${period} Installments`,
      data: data.map((item) => item["amount"]) || [],
    },
  ];
};

export const processPaid = (analyticsData) => {
  const xData =
    analyticsData?.analytics?.paidOffArray?.map((data) => data.month) || [];
  const paidOff =
    analyticsData?.analytics?.paidOffArray?.map((data) => data.totalPaidOff) ||
    [];
  return { xData, paidOff };
};

export const processPieData = (analyticsData, key, type) => {
  return (
    analyticsData?.analytics?.[key]?.map((item) => ({
      value: item.count,
      label: item[key],
    })) || []
  );
};
export const processWeekDates = (profitData) => {
  return (
    profitData?.dailyProfits?.weeklyProfits?.map((item) => item.weekNumber) ||
    []
  );
};

export const processDailyDates = (profitData) => {
  return profitData?.dailyProfits?.dailyProfits?.map((item) => item.date) || [];
};
