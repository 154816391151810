import { useState, useEffect } from "react";
// import fetchDashboardData from '../../services/fetchDashboardData'; // Adjust path as per your structure
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import usePostData from "../../../hooks/usePostData";

const useUsers = () => {
  const { postData } = usePostData();
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/form/all`);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  //   const [loading, setLoading] = useState(true);
  //   const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setUserData(data);

      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error]);
  const handleView = async (id) => {
    try {
      const result = await postData(`/form/${id}`);
      if (result.url) {
        window.open(result.url, "_blank");
      }
    } catch (error) {
      handleError(error, error.message);
      return;
    }
  };
  const handleDelete = async (id) => {
    try {
      setSubmitLoading(true);
      const result = await postData(`/form/delete/${id}`);
      if (result) {
        window.location.reload();
      }
    } catch (error) {
      setSubmitLoading(false);
      handleError(error, error.message);
      return;
    }
  };
  const handleUpdate = async (id, name, extension) => {
    try {
      setSubmitLoading(true);

      const result = await postData(`/form/update/${id}`, {
        formName: name,
        file: {
          extension: extension,
        },
      });
      const url = result.url;

      const blob = new Blob([selectedFile], {
        type: `application/${extension}`,
      });

      const resUpload = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": `application/${extension}`,
        },
        body: blob,
      });
      if (resUpload.status === 200) {
        window.location.reload();
      } else {
        setSubmitLoading(false);
        handleError("Failed to update file", "Failed to update file");
      }
    } catch (error) {
      setSubmitLoading(false);
      handleError(error, error.message);
      return;
    }
  };
  const handleCreate = async (name, extension, file) => {
    try {
      setSubmitLoading(true);
      const result = await postData(`/form/create`, {
        formName: name,
        file: {
          extension: extension,
        },
      });
      const url = result.url;

      const blob = new Blob([selectedFile], {
        type: `application/${extension}`,
      });

      const resUpload = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": `application/${extension}`,
        },
        body: blob,
      });
      if (resUpload.status === 200) {
        window.location.reload();
      } else {
        setSubmitLoading(false);
        handleError("Failed to update file", "Failed to update file");
      }
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);
      return;
    }
  };
  return {
    userData,
    loading,
    error,
    handleView,
    handleUpdate,
    handleDelete,
    handleCreate,
    submitLoading,
    setSelectedFile,
  };
};

export default useUsers;
