import React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import PropTypes from "prop-types";

const CustomLineChart = ({
  xData,
  seriesData,
  height,
  margin,
  grid,
  label,
}) => {
  return (
    <LineChart
      xAxis={[{ scaleType: "point", data: xData }]}
      series={[
        
        {
          data: seriesData,
          label:label
        },
      ]}
      height={height}
      // width={}
      // margin={margin}
      grid={grid}
    />
  );
};

CustomLineChart.propTypes = {
  xData: PropTypes.arrayOf(PropTypes.number).isRequired,
  seriesData: PropTypes.arrayOf(PropTypes.number).isRequired,
  height: PropTypes.number,
  margin: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number,
  }),
  grid: PropTypes.shape({
    vertical: PropTypes.bool,
    horizontal: PropTypes.bool,
  }),
};

CustomLineChart.defaultProps = {
  height: 300,
  margin: { left: 30, right: 30, top: 30, bottom: 30 },
  grid: { vertical: true, horizontal: true },
};

export default CustomLineChart;
