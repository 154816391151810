import useErrorHandler from "../../../hooks/useErrorHandler";
import usePostData from "../../../hooks/usePostData";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const useRole = () => {
  const handleError = useErrorHandler();
  const { postData } = usePostData();
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const handleCreate = async (role) => {
    try {
      setSubmitLoading(true);
      const result = await postData(`/role`, {
        ...role,
      });
      if (result) {
        navigate(`/roles/${result?.role?.id}`);
      }
      setSubmitLoading(false);
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);
      return;
    }
  };
  return { handleCreate, submitLoading };
};

export default useRole;
