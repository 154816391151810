import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.css";
import Button from "../../common/button";
import useRole from "./useRole";
import CustomRadioGroup from "./customRadioGroup";
import CustomInput from "../../common/customInput";
import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
const CreateRole = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      if (!user?.admin?.permissions?.permissions?.createRole) {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);
  const { handleCreate, submitLoading } = useRole();
  const [permissions, setPermissions] = useState({
    name: "",
    permissions: {
      createAdmin: false,
      updateAdmin: false,
      deleteAdmin: false,
      viewAdmin: false,
      createRole: false,
      deleteRole: false,
      emailAdmin: false,
      smsAdmin: false,
      emailUsers: false,
      smsUsers: false,
      viewInterestRates: false,
      updateInterestRates: false,
      viewUser: false,
      updateUser: false,
      handleDefaulter: false,
      viewDefaulters: false,
      viewForms: false,
      createForm: false,
      deleteForm: false,
      updateForm: false,
      applyForLoan: false,
      viewAnalytics: false,
      generateMonthlyDueStatement: false,
      generateStatisticsStatement: false,
      viewLoan: false,
      updateLoan: false,
      requestLoanItem: false,
      reviewLoan: false,
      approveLoan: false,
      fundLoan: false,
      rejectLoan: false,
      payInstallment: false,
      extendLoan: false,
      viewLiveLoanReport: false,
      generateLoanReports: false,
    },
  });

  const handlePermissionChange = (permission, value) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      permissions: {
        ...prevPermissions.permissions,
        [permission]: value,
      },
    }));
  };

  return (
    <section className={styles.user}>
      <>
        <div className={styles.form}>
          <h1>Create Role</h1>
          <CustomInput
            label={"Name"}
            className={styles.input}
            value={permissions?.name}
            onChange={(e) =>
              setPermissions((prevPermissions) => ({
                ...prevPermissions,
                name: e.target.value,
              }))
            }
          />
          <div className={styles.radio__div}>
            <CustomRadioGroup
              label="Can Create Admins?"
              value={permissions?.permissions?.createAdmin}
              selectedValue={permissions?.permissions?.createAdmin}
              onChange={(value) => handlePermissionChange("createAdmin", value)}
            />
            <CustomRadioGroup
              label="Can Update Admins?"
              value={permissions?.permissions?.updateAdmin}
              selectedValue={permissions?.permissions?.updateAdmin}
              onChange={(value) => handlePermissionChange("updateAdmin", value)}
            />
            <CustomRadioGroup
              label="Can Delete Admins?"
              value={permissions?.permissions?.deleteAdmin}
              selectedValue={permissions?.permissions?.deleteAdmin}
              onChange={(value) => handlePermissionChange("deleteAdmin", value)}
            />
            <CustomRadioGroup
              label="Can View Admins?"
              value={permissions?.permissions?.viewAdmin}
              selectedValue={permissions?.permissions?.viewAdmin}
              onChange={(value) => handlePermissionChange("viewAdmin", value)}
            />
            <CustomRadioGroup
              label="Can Create Roles?"
              value={permissions?.permissions?.createRole}
              selectedValue={permissions?.permissions?.createRole}
              onChange={(value) => handlePermissionChange("createRole", value)}
            />
            <CustomRadioGroup
              label="Can Delete Roles?"
              value={permissions?.permissions?.deleteRole}
              selectedValue={permissions?.permissions?.deleteRole}
              onChange={(value) => handlePermissionChange("deleteRole", value)}
            />
            <CustomRadioGroup
              label="Can Email Admins?"
              value={permissions?.permissions?.emailAdmin}
              selectedValue={permissions?.permissions?.emailAdmin}
              onChange={(value) => handlePermissionChange("emailAdmin", value)}
            />
            <CustomRadioGroup
              label="Can SMS Admins?"
              value={permissions?.permissions?.smsAdmin}
              selectedValue={permissions?.permissions?.smsAdmin}
              onChange={(value) => handlePermissionChange("smsAdmin", value)}
            />
            <CustomRadioGroup
              label="Can Email Users?"
              value={permissions?.permissions?.emailUsers}
              selectedValue={permissions?.permissions?.emailUsers}
              onChange={(value) => handlePermissionChange("emailUsers", value)}
            />
            <CustomRadioGroup
              label="Can SMS Users?"
              value={permissions?.permissions?.smsUsers}
              selectedValue={permissions?.permissions?.smsUsers}
              onChange={(value) => handlePermissionChange("smsUsers", value)}
            />
            <CustomRadioGroup
              label="Can View Interest Rates?"
              value={permissions?.permissions?.viewInterestRates}
              selectedValue={permissions?.permissions?.viewInterestRates}
              onChange={(value) =>
                handlePermissionChange("viewInterestRates", value)
              }
            />
            <CustomRadioGroup
              label="Can Update Interest Rates?"
              value={permissions?.permissions?.updateInterestRates}
              selectedValue={permissions?.permissions?.updateInterestRates}
              onChange={(value) =>
                handlePermissionChange("updateInterestRates", value)
              }
            />
            <CustomRadioGroup
              label="Can View Users?"
              value={permissions?.permissions?.viewUser}
              selectedValue={permissions?.permissions?.viewUser}
              onChange={(value) => handlePermissionChange("viewUser", value)}
            />
            <CustomRadioGroup
              label="Can Update Users?"
              value={permissions?.permissions?.updateUser}
              selectedValue={permissions?.permissions?.updateUser}
              onChange={(value) => handlePermissionChange("updateUser", value)}
            />
            <CustomRadioGroup
              label="Can Handle Defaulters?"
              value={permissions?.permissions?.handleDefaulter}
              selectedValue={permissions?.permissions?.handleDefaulter}
              onChange={(value) =>
                handlePermissionChange("handleDefaulter", value)
              }
            />
            <CustomRadioGroup
              label="Can View Defaulters?"
              value={permissions?.permissions?.viewDefaulters}
              selectedValue={permissions?.permissions?.viewDefaulters}
              onChange={(value) =>
                handlePermissionChange("viewDefaulters", value)
              }
            />
            <CustomRadioGroup
              label="Can View Forms?"
              value={permissions?.permissions?.viewForms}
              selectedValue={permissions?.permissions?.viewForms}
              onChange={(value) => handlePermissionChange("viewForms", value)}
            />
            <CustomRadioGroup
              label="Can Create Forms?"
              value={permissions?.permissions?.createForm}
              selectedValue={permissions?.permissions?.createForm}
              onChange={(value) => handlePermissionChange("createForm", value)}
            />
            <CustomRadioGroup
              label="Can Delete Forms?"
              value={permissions?.permissions?.deleteForm}
              selectedValue={permissions?.permissions?.deleteForm}
              onChange={(value) => handlePermissionChange("deleteForm", value)}
            />
            <CustomRadioGroup
              label="Can Update Forms?"
              value={permissions?.permissions?.updateForm}
              selectedValue={permissions?.permissions?.updateForm}
              onChange={(value) => handlePermissionChange("updateForm", value)}
            />
            <CustomRadioGroup
              label="Can Apply For Loans?"
              value={permissions?.permissions?.applyForLoan}
              selectedValue={permissions?.permissions?.applyForLoan}
              onChange={(value) =>
                handlePermissionChange("applyForLoan", value)
              }
            />
            <CustomRadioGroup
              label="Can View Analytics?"
              value={permissions?.permissions?.viewAnalytics}
              selectedValue={permissions?.permissions?.viewAnalytics}
              onChange={(value) =>
                handlePermissionChange("viewAnalytics", value)
              }
            />
            <CustomRadioGroup
              label="Can Generate Monthly Due Statements?"
              value={permissions?.permissions?.generateMonthlyDueStatement}
              selectedValue={
                permissions?.permissions?.generateMonthlyDueStatement
              }
              onChange={(value) =>
                handlePermissionChange("generateMonthlyDueStatement", value)
              }
            />
            <CustomRadioGroup
              label="Can Generate Statistics Statements?"
              value={permissions?.permissions?.generateStatisticsStatement}
              selectedValue={
                permissions?.permissions?.generateStatisticsStatement
              }
              onChange={(value) =>
                handlePermissionChange("generateStatisticsStatement", value)
              }
            />
            <CustomRadioGroup
              label="Can View Loans?"
              value={permissions?.permissions?.viewLoan}
              selectedValue={permissions?.permissions?.viewLoan}
              onChange={(value) => handlePermissionChange("viewLoan", value)}
            />
            <CustomRadioGroup
              label="Can Update Loans?"
              value={permissions?.permissions?.updateLoan}
              selectedValue={permissions?.permissions?.updateLoan}
              onChange={(value) => handlePermissionChange("updateLoan", value)}
            />
            <CustomRadioGroup
              label="Can Request Loan Items?"
              value={permissions?.permissions?.requestLoanItem}
              selectedValue={permissions?.permissions?.requestLoanItem}
              onChange={(value) =>
                handlePermissionChange("requestLoanItem", value)
              }
            />
            <CustomRadioGroup
              label="Can Review Loans?"
              value={permissions?.permissions?.reviewLoan}
              selectedValue={permissions?.permissions?.reviewLoan}
              onChange={(value) => handlePermissionChange("reviewLoan", value)}
            />
            <CustomRadioGroup
              label="Can Approve Loans?"
              value={permissions?.permissions?.approveLoan}
              selectedValue={permissions?.permissions?.approveLoan}
              onChange={(value) => handlePermissionChange("approveLoan", value)}
            />
            <CustomRadioGroup
              label="Can Fund Loans?"
              value={permissions?.permissions?.fundLoan}
              selectedValue={permissions?.permissions?.fundLoan}
              onChange={(value) => handlePermissionChange("fundLoan", value)}
            />
            <CustomRadioGroup
              label="Can Reject Loans?"
              value={permissions?.permissions?.rejectLoan}
              selectedValue={permissions?.permissions?.rejectLoan}
              onChange={(value) => handlePermissionChange("rejectLoan", value)}
            />
            <CustomRadioGroup
              label="Can Pay Installments?"
              value={permissions?.permissions?.payInstallment}
              selectedValue={permissions?.permissions?.payInstallment}
              onChange={(value) =>
                handlePermissionChange("payInstallment", value)
              }
            />
            <CustomRadioGroup
              label="Can Extend Loans?"
              value={permissions?.permissions?.extendLoan}
              selectedValue={permissions?.permissions?.extendLoan}
              onChange={(value) => handlePermissionChange("extendLoan", value)}
            />
            <CustomRadioGroup
              label="Can View Live Loan Reports?"
              value={permissions?.permissions?.viewLiveLoanReport}
              selectedValue={permissions?.permissions?.viewLiveLoanReport}
              onChange={(value) =>
                handlePermissionChange("viewLiveLoanReport", value)
              }
            />
            <CustomRadioGroup
              label="Can Generate Loan Reports?"
              value={permissions?.permissions?.generateLoanReports}
              selectedValue={permissions?.permissions?.generateLoanReports}
              onChange={(value) =>
                handlePermissionChange("generateLoanReports", value)
              }
            />
          </div>
          <div className={styles.form__buttons}>
            <Button
              type="submit"
              className={styles.submit}
              onClick={() => {
                handleCreate(permissions);
              }}
              loading={submitLoading}
              disabled={!permissions?.name}
            >
              Submit
            </Button>
            <Button
              type="cancel"
              className={`${styles.submit} ${styles.cancel}`}
              onClick={() => {
                window.location.reload();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </>
    </section>
  );
};

export default CreateRole;
