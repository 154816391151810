import React, { useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Logo from "./../../../images/Logo.svg";
import TollIcon from "@mui/icons-material/Toll";
import PaymentsIcon from "@mui/icons-material/Payments";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styles from "./sidebar.module.css";
import ApprovalIcon from "@mui/icons-material/Approval";
import { useTheme } from "../../../contexts/themeContext";
import CustomTooltip from "../../common/customToolTip";
import { useNavigate } from "react-router-dom";
import Slide from "@mui/material/Slide";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import BallotIcon from "@mui/icons-material/Ballot";
import SendIcon from "@mui/icons-material/Send";
import { useAuth } from "../../../contexts/authContext";
import BlockIcon from "@mui/icons-material/Block";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
function Sidebar({
  isCollapsed,
  isOpen,
  toggleCollapse,
  active,
  setActive,
  subMenuActive,
  setSubMenuActive,
}) {
  const { darkMode } = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [subMenuOpen, setSubMenuOpen] = useState(true);
  const handleSidebarClick = (path) => {
    navigate(`/${path.toLowerCase().split(" ")[0]}`);
  };
  const handleSubMenuClick = (path) => {
    navigate(`/loans?type=${path}`);
    setSubMenuActive(path);
  };
  const renderButtonWithTooltip = (
    IconComponent,
    label,
    onClickAction,
    isActive = active === label.toLowerCase()
  ) => {
    const button = (
      <button
        className={`${styles.nav__button} ${isActive ? styles.active : ""}`}
        onClick={() => {
          if (onClickAction) {
            onClickAction();
          } else {
            setActive(`${label.toLowerCase()}`);
            setSubMenuActive("");
            handleSidebarClick(label);
          }
        }}
        style={{
          backgroundColor: isActive
            ? darkMode
              ? "var(--hover-color)"
              : "var(--hover-color)"
            : "transparent",
          color: isActive ? "var(--primary-color)" : "inherit",
        }}
      >
        {IconComponent && (
          <IconComponent
            fontSize={"2rem"}
            color={isActive ? "white" : "inherit"}
          />
        )}
        {!isCollapsed && label}
      </button>
    );

    return isCollapsed ? (
      <CustomTooltip title={label} placement="right">
        {button}
      </CustomTooltip>
    ) : (
      button
    );
  };

  return (
    <section
      className={`${styles.sidebar} ${isCollapsed ? styles.collapsed : ""}`}
    >
      <div className={styles.inner}>
        <div className={styles.top__nav}>
          <img src={Logo} alt="logo" className={styles.logoImage} />
          <Hamburger toggled={isOpen} toggle={toggleCollapse} />
        </div>
        <div className={styles.user__details}>
          <div className={styles.user__icon}>
            <AccountCircleIcon fontSize={"3rem"} />
          </div>
          {!isCollapsed && (
            <div className={styles.user__information}>
              <h3>
                {user?.admin?.firstName} {user?.admin?.surName}
              </h3>
            </div>
          )}
        </div>
        <div className={styles.main}>
          {renderButtonWithTooltip(DashboardIcon, "Dashboard")}
          {renderButtonWithTooltip(PaymentsIcon, "Loans", () => {
            setSubMenuOpen(!subMenuOpen);
          })}
          {subMenuOpen && (
            <Slide
              direction="right"
              in={subMenuOpen}
              mountOnEnter
              unmountOnExit
            >
              <div className={styles.submenu}>
                {renderButtonWithTooltip(
                  null,
                  "All Loans",
                  () => {
                    setActive(`loans`);

                    handleSubMenuClick("all");
                  },
                  subMenuActive === "all"
                )}
                {renderButtonWithTooltip(
                  null,
                  "Pending Loans",
                  () => {
                    setActive(`loans`);

                    handleSubMenuClick("pending");
                  },
                  subMenuActive === "pending"
                )}
                {renderButtonWithTooltip(
                  null,
                  "Reviewed Loans",
                  () => {
                    setActive(`loans`);

                    handleSubMenuClick("reviewed");
                  },
                  subMenuActive === "reviewed"
                )}
                {renderButtonWithTooltip(
                  null,
                  "Approved Loans",
                  () => {
                    setActive(`loans`);

                    handleSubMenuClick("approved");
                  },
                  subMenuActive === "approved"
                )}
                {renderButtonWithTooltip(
                  null,
                  "Funded Loans",
                  () => {
                    setActive(`loans`);

                    handleSubMenuClick("funded");
                  },
                  subMenuActive === "funded"
                )}
                {renderButtonWithTooltip(
                  null,
                  "Rejected Loans",
                  () => {
                    setActive(`loans`);

                    handleSubMenuClick("rejected");
                  },
                  subMenuActive === "rejected"
                )}
                {renderButtonWithTooltip(
                  null,
                  "Paid Loans",
                  () => {
                    setActive(`loans`);

                    handleSubMenuClick("paid");
                  },
                  subMenuActive === "paid"
                )}
                {renderButtonWithTooltip(
                  null,
                  "Overdue Loans",
                  () => {
                    setActive(`loans`);

                    handleSubMenuClick("overdue");
                  },
                  subMenuActive === "overdue"
                )}
              </div>
            </Slide>
          )}
          {renderButtonWithTooltip(SummarizeIcon, "Monthly Statement")}
          {renderButtonWithTooltip(AnalyticsIcon, "Analytics")}
          {renderButtonWithTooltip(ApprovalIcon, "Apply for Loan")}
          {renderButtonWithTooltip(BlockIcon, "Defaulters")}
          {renderButtonWithTooltip(PictureAsPdfIcon, "Forms")}
          {renderButtonWithTooltip(GroupIcon, "Users")}
          {renderButtonWithTooltip(SupervisorAccountIcon, "Admins")}
          {renderButtonWithTooltip(BallotIcon, "Roles")}
          {renderButtonWithTooltip(TollIcon, "Interest Rates")}
          {renderButtonWithTooltip(SendIcon, "Email / SMS")}
          {renderButtonWithTooltip(AnalyticsIcon, "Companies")}
        </div>
      </div>
    </section>
  );
}

export default Sidebar;
