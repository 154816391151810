// src/components/Dashboard.js
import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.css";
import useAnalyticsData from "./useAnalytics";
import Skeleton from "@mui/material/Skeleton";
import CustomLineChart from "../../common/lineChart";
import CustomBarChart from "../../common/barChart";
import CustomPieChart from "../../common/pieChart";
import {
  processMonthlyDisbursed,
  processOutstanding,
  processMonthlyProfit,
  processProfit,
  processUpcomingInstallments,
  processPaid,
  processPieData,
  processWeekDates,
  processDailyDates,
} from "./dataProcessing";
import TimingPopup from "./timingPopup";
import { AuthContext } from "./../../../contexts/authContext";
import { useNavigate } from "react-router-dom";
const Analytics = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      if (!user?.admin?.permissions?.permissions?.viewAnalytics) {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);
  const { analyticsData, loading, profitData, barLoading } = useAnalyticsData();
  const [chartsData, setChartsData] = useState({
    monthlyDisbursed: { xData: [], seriesData: [] },
    outstanding: [],
    monthlyProfit: [],
    weeklyProfit: [],
    dailyProfit: [],
    monthlyUpcoming: [],
    weeklyUpcoming: [],
    dailyUpcoming: [],
    paid: { xData: [], paidOff: [] },
    statusWise: [],
    loanType: [],
  });
  const [weekDates, setWeekDates] = useState([]);
  const [dailyDates, setDailyDates] = useState([]);
  useEffect(() => {
    if (analyticsData && profitData) {
      setChartsData({
        monthlyDisbursed: processMonthlyDisbursed(analyticsData),
        outstanding: processOutstanding(analyticsData),
        monthlyProfit: processMonthlyProfit(profitData),
        weeklyProfit: processProfit(profitData, "weeklyProfits"),
        dailyProfit: processProfit(profitData, "dailyProfits"),
        monthlyUpcoming: processUpcomingInstallments(profitData, "Monthly"),
        weeklyUpcoming: processUpcomingInstallments(profitData, "Weekly"),
        dailyUpcoming: processUpcomingInstallments(profitData, "Daily"),
        paid: processPaid(analyticsData),
        statusWise: processPieData(analyticsData, "status"),
        loanType: processPieData(analyticsData, "loanType"),
      });
      setWeekDates(processWeekDates(profitData));
      setDailyDates(processDailyDates(profitData));
    }
  }, [analyticsData, profitData]);

  const renderChart = (condition, ChartComponent, props) =>
    condition ? <ChartComponent {...props} /> : null;
  const [profitTiming, setProfitTiming] = useState("Daily");
  const [upcomingTiming, setUpcomingTiming] = useState("Daily");

  return (
    <section className={styles.analytics}>
      <div className={styles.cards}>
        {loading || barLoading ? (
          Array.from({ length: 8 }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              width={"25%"}
              height={150}
            />
          ))
        ) : (
          <>
            <div className={styles.charts__group}>
              <div className={styles.charts__group__heading}>
                <h3>Profit</h3>
                <TimingPopup
                  selected={profitTiming}
                  timingClick={setProfitTiming}
                />
              </div>
              {renderChart(
                chartsData.monthlyProfit.length > 0 &&
                  chartsData.monthlyProfit[0].data.length > 0 &&
                  profitTiming === "Monthly",

                CustomBarChart,
                {
                  data: chartsData.monthlyProfit,
                  height: 400,
                  yAxis: chartsData.monthlyDisbursed.xData,
                }
              )}
              {renderChart(
                chartsData.weeklyProfit.length > 0 &&
                  chartsData.weeklyProfit[0].data.length > 0 &&
                  profitTiming === "Weekly",
                CustomBarChart,
                {
                  data: chartsData.weeklyProfit,
                  height: 400,
                  yAxis: weekDates,
                }
              )}
              {renderChart(
                chartsData.dailyProfit.length > 0 &&
                  chartsData.dailyProfit[0].data.length > 0 &&
                  profitTiming === "Daily",
                CustomBarChart,
                {
                  data: chartsData.dailyProfit,
                  height: 400,
                  yAxis: dailyDates,
                }
              )}
            </div>
            <div className={styles.charts__group}>
              <div className={styles.charts__group__heading}>
                <h3>Upcoming Installments</h3>
                <TimingPopup
                  selected={upcomingTiming}
                  timingClick={setUpcomingTiming}
                />
              </div>
              {renderChart(
                chartsData.dailyUpcoming.length > 0 &&
                  chartsData.dailyUpcoming[0].data.length > 0 &&
                  upcomingTiming === "Daily",
                CustomBarChart,
                {
                  data: chartsData.dailyUpcoming,
                  height: 400,
                  yAxis: dailyDates,
                }
              )}
              {renderChart(
                chartsData.weeklyUpcoming.length > 0 &&
                  chartsData.weeklyUpcoming[0].data.length > 0 &&
                  upcomingTiming === "Weekly",
                CustomBarChart,
                {
                  data: chartsData.weeklyUpcoming,
                  height: 400,
                  yAxis: weekDates,
                }
              )}
              {renderChart(
                chartsData.monthlyUpcoming.length > 0 &&
                  chartsData.monthlyUpcoming[0].data.length > 0 &&
                  upcomingTiming === "Monthly",
                CustomBarChart,
                {
                  data: chartsData.monthlyUpcoming,
                  height: 400,
                  yAxis: chartsData.monthlyDisbursed.xData,
                }
              )}
            </div>
            {renderChart(
              chartsData.monthlyDisbursed.xData.length > 0 &&
                chartsData.monthlyDisbursed.seriesData.length > 0,
              CustomLineChart,
              {
                xData: chartsData.monthlyDisbursed.xData,
                seriesData: chartsData.monthlyDisbursed.seriesData,
                height: 400,
                grid: { vertical: true, horizontal: true },
                label: "Monthly Disbursed Amount",
              }
            )}
            {renderChart(
              chartsData.outstanding.length > 0 &&
                chartsData.outstanding[0].data.length > 0,
              CustomBarChart,
              {
                data: chartsData.outstanding,
                height: 400,
                yAxis: chartsData.monthlyDisbursed.xData,
              }
            )}

            {renderChart(
              chartsData.paid.xData.length > 0 &&
                chartsData.paid.paidOff.length > 0,
              CustomLineChart,
              {
                xData: chartsData.paid.xData,
                seriesData: chartsData.paid.paidOff,
                height: 400,
                grid: { vertical: true, horizontal: true },
                label: "Total Amount Paid Off",
              }
            )}
            {renderChart(chartsData.statusWise.length > 0, CustomPieChart, {
              data: chartsData.statusWise,
              height: 400,
            })}
            {renderChart(chartsData.loanType.length > 0, CustomPieChart, {
              data: chartsData.loanType,
              height: 400,
            })}
          </>
        )}
      </div>
    </section>
  );
};

export default Analytics;
