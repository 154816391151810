import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { useTheme } from "../../../contexts/themeContext";
import styles from "./index.module.css";
import Button from "../../common/button";
import CustomInput from "../../common/customInput";
const PayModal = ({
  onClick,
  open,
  setOpen,
  loading,
  input,
  setInput,
  error,
  disabled,
}) => {
  const { darkMode } = useTheme();
  const [type, setType] = useState(0);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      data-theme={darkMode ? "dark" : "light"}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.modal}>
        <div className={styles.container}>
          <h3>Select the Type of Installment you want to pay</h3>
          <div className={styles.container__inner}>
            <Button
              children={"Preset Amount"}
              onClick={() => {
                setType(0);
                onClick();
              }}
              loading={loading}
            />
            <Button
              children={"Specify Amount"}
              onClick={() => {
                setType(1);
              }}
            />
          </div>
          {type === 1 && (
            <div className={styles.specify}>
              <CustomInput
                value={input}
                label={"Amount"}
                className={styles.modal__input}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                error={error}
              />
              <Button
                children={"Pay"}
                onClick={onClick}
                loading={loading}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default PayModal;
