// src/services/api.js
import { handleResponse, handleError } from "../utils/apiHelpers";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_FRONTEND_URL
    : process.env.REACT_APP_LOCAL_URL;

const apiClient = {
  get: async (endpoint, token) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      return await handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
  post: async (endpoint, data, token) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(data),
      });
      return await handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
  put: async (endpoint, data, token) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(data),
      });
      return await handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
  delete: async (endpoint, token) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "DELETE",
        headers: {
          Authorization: `${token}`,
        },
      });
      return await handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  },
};

export default apiClient;
