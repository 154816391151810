// src/components/common/Button.js
import React from "react";
import PropTypes from "prop-types";
import styles from "./button.module.css";
import { TailSpin } from "react-loader-spinner";
const Button = ({
  onClick,
  children,
  type = "button",
  className,
  loading,
  disabled,
}) => {
  const combinedClassName = `${styles.btn} ${
    className ? className : ""
  }`.trim();

  return !loading ? (
    <button className={combinedClassName} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  ) : (
    <button className={combinedClassName} onClick={onClick} disabled>
      <TailSpin
        color={"var(--button-primary)"}
        visible={loading}
        height="30"
        width="100"
        radius="0"
      />
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Button;
