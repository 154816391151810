// src/contexts/NotificationContext.js
import React, { createContext, useContext } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import Slide from "@mui/material/Slide";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      TransitionComponent: Slide,
      autoHideDuration: 5000,
    });
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

const NotificationWrapper = ({ children }) => (
  <SnackbarProvider maxSnack={3}>
    <NotificationProvider>{children}</NotificationProvider>
  </SnackbarProvider>
);

export default NotificationWrapper;
