// src/utils/apiHelpers.js
export const handleResponse = async (response) => {
  if (response.status !== 200) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Network response was not ok");
  }
  return await response.json();
};

export const handleError = (error) => {
  console.error("API call failed. " + error);
  throw new Error(error);
};
