import { useState, useEffect } from "react";
// import fetchDashboardData from '../../services/fetchDashboardData'; // Adjust path as per your structure
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";

const useAnalyticsData = () => {
  const { data, loading, error } = useFetchData("/analytics");
  const { data: barData, loading: barLoading } = useFetchData("/profit");
  const [profitData, setProfitData] = useState(null);
  const handleError = useErrorHandler();
  const [analyticsData, setAnalyticsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setAnalyticsData(data);
      setProfitData(barData);
      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error, barData]);

  return { analyticsData, loading, error, profitData, barLoading };
};

export default useAnalyticsData;
