import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import usePostData from "../../../hooks/usePostData";

const useStatement = () => {
  const { data, loading, error } = useFetchData("/employers");
  const { data: products } = useFetchData(`/products`);

  const handleError = useErrorHandler();
  const { postData } = usePostData();

  const [employerData, setEmployerData] = useState(null);
  const [loadingStatement, setLoadingStatement] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    if (error) {
      handleError(error, error.message);
    } else {
      setEmployerData(data);
      setProductData(products);
    }
  }, [data, error, handleError, products]);

  const handleMonthlyStatement = async (month, employer) => {
    try {
      setLoadingStatement(true);
      const inputData = { month: month, employer: employer[0] };
      const response = await postData(`/loans/monthly`, inputData);

      setLoadingStatement(false);

      if (response?.url) {
        window.open(response.url, "_blank");
      }
    } catch (error) {
      handleError(error, error.message);
      setLoadingStatement(false);
      return;
    }
  };

  const handleGenerateReport = async (employer, startDate, endDate, type) => {
    try {
      setLoadingReport(true);
      const inputData = {
        employer: employer,
        startDate: startDate,
        endDate: endDate,
        loanType: type,
      };

      const response = await postData(`/loan/statistics`, inputData);

      setLoadingReport(false);

      if (response?.url) {
        window.open(response.url, "_blank");
      }
    } catch (error) {
      handleError(error, error.message);
      setLoadingReport(false);
      return;
    }
  };

  return {
    employerData,
    loading,
    error,
    handleMonthlyStatement,
    loadingStatement,
    handleGenerateReport,
    loadingReport,
    productData,
  };
};

export default useStatement;
