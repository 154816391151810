// Validation functions
export const validateTitle = (value) => {
  if (!value) return "Title is required.";
  return null;
};

export const validateFirstName = (value) => {
  if (!value) return "First Name is required.";
  if (value.length < 2) return "First Name must be at least 2 characters long.";
  return null;
};

export const validateSurName = (value) => {
  if (!value) return "Surname is required.";
  if (value.length < 2) return "Surname must be at least 2 characters long.";
  return null;
};

export const validateGender = (value) => {
  if (!value) return "Gender is required.";
  return null;
};

export const validatePhoneNumber = (value) => {
  const ukRegex = /^\+44[0-9]{10}$/; // UK phone number format: +44 followed by 10 digits
  const zambiaRegex = /^\+260[0-9]{9}$/; // Zambia phone number format: +260 followed by 9 digits
  const pakistanRegex = /^\+92[0-9]{10}$/; // Pakistan phone number format: +92 followed by 10 digits

  if (!value) return "Phone Number is required.";
  if (
    !ukRegex.test(value) &&
    !zambiaRegex.test(value) &&
    !pakistanRegex.test(value)
  ) {
    return "Phone Number is invalid.";
  }

  return null;
};

export const validateNRCNumber = (value) => {
  const zambiaRegex = /^\d{6}\/\d{2}\/\d{1}$/; // Zambian NRC format: 123456/12/1
  const ukRegex = /^[A-Z]{2}\d{6}[A-Z]$/; // UK NINO format: AB123456C
  const pakistanRegex = /^\d{13}$/; // Pakistan CNIC format: 1234567890123

  if (!value) return "NRC Number is required.";

  if (
    !zambiaRegex.test(value) &&
    !ukRegex.test(value) &&
    !pakistanRegex.test(value)
  ) {
    return "NRC Number is invalid.";
  }

  return null;
};
export const validateDateOfBirth = (value) => {
  if (!value) return "Date of Birth is required.";
  // Calculate today's date

  // Calculate minimum and maximum birth dates
  const minBirthDate = new Date();
  minBirthDate.setFullYear(minBirthDate.getFullYear() - 100); // 100 years ago from today
  const maxBirthDate = new Date();
  maxBirthDate.setFullYear(maxBirthDate.getFullYear() - 12); // 12 years ago from today

  // Parse the input date value
  const birthDate = new Date(value);
  // Check if the parsed date is valid
  if (isNaN(birthDate.getTime())) {
    return "Invalid Date of Birth.";
  }

  // Check if the birth date is within the allowed range
  if (birthDate < minBirthDate || birthDate > maxBirthDate) {
    return "Age must be between 12 and 100 years.";
  }

  return null;
};

export const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value) return "Email is required.";
  if (!emailRegex.test(value)) return "Email is invalid.";
  return null;
};

export const validateResidentialAddressNumber = (value) => {
  if (!value) return "Residential Address Number is required.";
  return null;
};

export const validateStreetName = (value) => {
  if (!value) return "Street Name is required.";
  return null;
};

export const validateResidentialArea = (value) => {
  if (!value) return "Residential Area is required.";
  return null;
};

export const validateNationality = (value) => {
  if (!value) return "Nationality is required.";
  return null;
};

export const validateBankName = (value) => {
  if (!value) return "Bank Name is required.";
  return null;
};

export const validateAccountNumber = (value) => {
  if (!value) return "Account Number is required.";
  return null;
};

export const validateLoanType = (value) => {
  if (!value) return "Loan Type is required.";
  return null;
};

export const validateAddressDuration = (value) => {
  if (!value) return "Address Duration is required.";
  return null;
};

export const validateNetMonthlyIncome = (value) => {
  if (!value) return "Net Monthly Income is required.";
  return null;
};

export const validateEmploymentStatus = (value) => {
  if (!value) return "Employment Status is required.";
  return null;
};

export const validatePublicService = (value) => {
  if (!value) return "Public Service is required.";
  return null;
};

export const validateEmployer = (value) => {
  if (!value) return "Employer is required.";
  return null;
};

export const validateEmployeeNumber = (value) => {
  if (!value) return "Employee Number is required.";
  return null;
};

export const validateLoanAmount = (value) => {
  if (!value) return "Loan Amount is required.";
  return null;
};

export const validateLoanDuration = (value) => {
  if (!value) return "Loan Duration is required.";
  return null;
};

export const validateInterestFee = (value) => {
  if (!value) return "Interest Fee is required.";
  return null;
};

export const validateProcessingFee = (value) => {
  if (!value) return "Processing Fee is required.";
  return null;
};

export const validateInsuranceFee = (value) => {
  if (!value) return "Insurance Fee is required.";
  return null;
};

export const validatePenaltyFee = (value) => {
  if (!value) return "Penalty Fee is required.";
  return null;
};

export const validateMonthlyInstallments = (value) => {
  if (!value) return "Monthly Installments are required.";
  return null;
};

export const validateTotal = (value) => {
  if (!value) return "Total is required.";
  return null;
};

export const validateDocument = (file, name) => {
  if (name !== "preApprovedLetter") {
    if (!file) return "This document is required.";
  }

  if (file) {
    const allowedTypes = [
      "jpeg",
      "jpg",
      "png",
      "pdf",
      "msword",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!allowedTypes.includes(file.extension)) {
      return "Please upload a valid document type (JPEG, PNG, PDF, DOC, DOCX).";
    } else {
      return null;
    }
  }

  return null;
};

export const validateAllFields = (formData) => {
  const errors = {};

  errors.title = validateTitle(formData.title);
  errors.firstName = validateFirstName(formData.firstName);
  errors.surName = validateSurName(formData.surName);
  errors.gender = validateGender(formData.gender);
  errors.phoneNumber = validatePhoneNumber(formData.phoneNumber);
  errors.NRCNumber = validateNRCNumber(formData.NRCNumber);
  errors.dateOfBirth = validateDateOfBirth(formData.dateOfBirth);
  errors.email = validateEmail(formData.email);
  errors.residentialAddressNumber = validateResidentialAddressNumber(
    formData.residentialAddressNumber
  );
  errors.streetName = validateStreetName(formData.streetName);
  errors.residentialArea = validateResidentialArea(formData.residentialArea);
  errors.nationality = validateNationality(formData.nationality);
  errors.bankName = validateBankName(formData.bankName);
  errors.accountNumber = validateAccountNumber(formData.accountNumber);
  errors.loanType = validateLoanType(formData.loanType);
  errors.addressDuration = validateAddressDuration(formData.addressDuration);
  errors.netMonthlyIncome = validateNetMonthlyIncome(formData.netMonthlyIncome);
  errors.employmentStatus = validateEmploymentStatus(formData.employmentStatus);
  errors.publicService = validatePublicService(formData.publicService);
  errors.employer = validateEmployer(formData.employer);
  errors.employeeNumber = validateEmployeeNumber(formData.employeeNumber);
  errors.loanAmount = validateLoanAmount(formData.loanAmount);
  errors.loanDuration = validateLoanDuration(formData.loanDuration);
  errors.interestFee = validateInterestFee(formData.interestFee);
  errors.processingFee = validateProcessingFee(formData.processingFee);
  errors.insuranceFee = validateInsuranceFee(formData.insuranceFee);
  errors.penaltyFee = validatePenaltyFee(formData.penaltyFee);
  errors.monthlyInstallments = validateMonthlyInstallments(
    formData.monthlyInstallments
  );
  errors.total = validateTotal(formData.total);
  errors.identityDocument = validateDocument(formData.identityDocument);
  errors.paySlip1 = validateDocument(formData.paySlip1);
  errors.paySlip2 = validateDocument(formData.paySlip2);
  errors.proofOfAddress = validateDocument(formData.proofOfAddress);
  errors.preApprovedLetter = validateDocument(formData.preApprovedLetter);

  // Remove any null values from the errors object
  Object.keys(errors).forEach((key) => {
    if (errors[key] === null) {
      delete errors[key];
    }
  });

  return errors;
};
export const validateUser = (formData) => {
  const errors = {};

  errors.title = validateTitle(formData.title);
  errors.firstName = validateFirstName(formData.firstName);
  errors.surName = validateSurName(formData.surName);
  errors.gender = validateGender(formData.gender);
  errors.phoneNumber = validatePhoneNumber(formData.phoneNumber);
  errors.NRCNumber = validateNRCNumber(formData.NRCNumber);
  errors.dateOfBirth = validateDateOfBirth(formData.dateOfBirth);
  errors.email = validateEmail(formData.email);
  errors.residentialAddressNumber = validateResidentialAddressNumber(
    formData.residentialAddressNumber
  );
  errors.streetName = validateStreetName(formData.streetName);
  errors.residentialArea = validateResidentialArea(formData.residentialArea);
  errors.nationality = validateNationality(formData.nationality);
  errors.bankName = validateBankName(formData.bankName);
  errors.accountNumber = validateAccountNumber(formData.accountNumber);
  Object.keys(errors).forEach((key) => {
    if (errors[key] === null) {
      delete errors[key];
    }
  });
  return errors;
};
export const validateLoanInformation = (formData) => {
  const errors = {};

  errors.loanType = validateLoanType(formData.loanType);
  errors.addressDuration = validateAddressDuration(formData.addressDuration);
  errors.netMonthlyIncome = validateNetMonthlyIncome(formData.netMonthlyIncome);
  errors.employmentStatus = validateEmploymentStatus(formData.employmentStatus);
  errors.publicService = validatePublicService(formData.publicService);
  errors.employer = validateEmployer(formData.employer);
  errors.employeeNumber = validateEmployeeNumber(formData.employeeNumber);
  Object.keys(errors).forEach((key) => {
    if (errors[key] === null) {
      delete errors[key];
    }
  });
  return errors;
};
export const validateLoanFinances = (formData) => {
  const errors = {};

  errors.loanAmount = validateLoanAmount(formData.loanAmount);
  errors.loanDuration = validateLoanDuration(formData.loanPeriod);
  Object.keys(errors).forEach((key) => {
    if (errors[key] === null) {
      delete errors[key];
    }
  });
  return errors;
};

export const validateLoanDocuments = (formData) => {
  const errors = {};

  return errors;
};
