// src/components/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import Logo from "./../../../images/Logo.svg";
import Button from "../../common/button";
import CustomInput from "../../common/customInput";
import useForget from "./useForget";
import { validateEmail } from "./validateForget";
const ForgetPassword = () => {
  const [email, setEmail] = useState("");

  const { isEmailSent, handleSubmitEmail, loading } = useForget();
  const [disable, setDisable] = useState(true);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    navigate(`/reset?email=${email}`);
  };
  const [error, setError] = useState("");
  const validateEmailHelper = (value) => {
    const emailError = validateEmail(value);
    setError(emailError);
    setEmail(value);
    if (emailError) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };
  return (
    <section className={styles.login}>
      {!isEmailSent ? (
        <div className={styles.container}>
          <div className={styles.logo}>
            <img src={Logo} alt="Vitpay" />
          </div>
          <div className={styles.form}>
            <h3>Forgot Password?</h3>
            <div className={styles.form__div}>
              <CustomInput
                type="text"
                placeholder="Email"
                value={email}
                onChange={(event) => validateEmailHelper(event.target.value)}
                label={"Email"}
                className={styles.input}
                error={error}
              />

              <Button
                children={"Send OTP"}
                onClick={() => {
                  handleSubmitEmail(email);
                }}
                className={styles.primary}
                loading={loading}
                disabled={disable}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.logo}>
            <img src={Logo} alt="Vitpay" />
          </div>
          <div className={styles.email_sent}>
            <h3>Email Sent</h3>

            <p>
              An Email has been sent to you with the otp. Please proceed to
              reset.
            </p>
            <Button
              children={"Reset Now"}
              onClick={handleSubmit}
              className={styles.primary}
              loading={false}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default ForgetPassword;
