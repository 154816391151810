import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import usePassword from "./usePassword";
import {
  validatePasswordChange,
  validateNewPassword,
  validateOldPassword,
  validateConfirmPassword,
} from "./validatePassword";
const UpdatePassword = () => {
  const { handleUpdatePassword, submitLoading } = usePassword();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    oldPassword: "",
  });

  useEffect(() => {
    setFormData({
      password: "",
      confirmPassword: "",
      oldPassword: "",
    });
  }, []);

  const [errors, setErrors] = useState({});
  useEffect(() => {
    const validationErrors = validatePasswordChange(formData);
    // setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [formData]);
  const handleChange = (field) => (event) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: event?.target?.value || event,
    }));
  };
  const handleError = (field) => (event) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: event,
    }));
  };
  return (
    <section className={styles.user}>
      <>
        <div className={styles.form}>
          <h1>Update Password</h1>
          <div className={styles.form__inner}>
            <CustomInput
              placeholder="Old Password"
              value={formData.oldPassword}
              type="password"
              label="Old Password"
              onChange={(e) => {
                handleError("oldPassword")(validateOldPassword(e.target.value));
                handleChange("oldPassword")(e.target.value);
              }}
              className={styles.input}
              error={errors.oldPassword}
            />
            <CustomInput
              placeholder="New Password"
              value={formData.password}
              type="password"
              label="New Password"
              onChange={(e) => {
                handleError("password")(validateNewPassword(e.target.value));
                handleChange("password")(e.target.value);
              }}
              error={errors.password}
              className={styles.input}
            />
            <CustomInput
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              type="password"
              label="Confirm Password"
              onChange={(e) => {
                handleError("confirmPassword")(
                  validateConfirmPassword(e.target.value, formData.password)
                );
                handleChange("confirmPassword")(e.target.value);
              }}
              error={errors.confirmPassword}
              className={styles.input}
            />
          </div>
          <div className={styles.form__buttons}>
            <Button
              type="submit"
              className={styles.submit}
              onClick={() => {
                handleUpdatePassword(formData?.password, formData?.oldPassword);
              }}
              disabled={submitDisabled}
              loading={submitLoading}
            >
              Update Password
            </Button>
          </div>
        </div>
      </>
    </section>
  );
};

export default UpdatePassword;
