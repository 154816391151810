import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Logo from "./../../../images/Logo.svg";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import useProfile from "./useProfile";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
const Profile = () => {
  const { profile, loading } = useProfile();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    surName: "Doe",
    phoneNumber: "+123456789",
    password: "",
    kycStatus: "active",
    country: "PK",
    NRCNumber: "ZN123456",
    countryCode: "PK",
    verified: true,
    status: "active",
    pin: "1234",
    otp: "1234",
    otpExpiresAt: "",
    createdAt: "",
  });

  useEffect(() => {
    setFormData({
      firstName: profile?.admin?.firstName || "",
      surName: profile?.admin?.surName || "",
      phoneNumber: profile?.admin?.phoneNumber || "",
      accountNumber: profile?.admin?.accountNumber || "",
      bankName: profile?.admin?.bankName || "",
      dateOfBirth: profile?.admin?.dateOfBirth || "",
      gender: profile?.admin?.gender || "",
      password: "",
      status: profile?.admin?.status || "",
      country: profile?.admin?.nationality || "Zambia",
      NRCNumber: profile?.admin?.NRCNumber || "",
      verified: profile?.admin?.isVerified || "",
      loginAttempt: profile?.admin?.loginAttempt || "",
      residentialAddressNumber: profile?.admin?.residentialAddressNumber || "",
      residentialArea: profile?.admin?.residentialArea || "",
      streetName: profile?.admin?.streetName || "",
      title: profile?.admin?.title || "",
      otp: "",
      email: profile?.admin?.email || "",
      otpExpiresAt: profile?.admin?.otpExpiresAt,
      createdAt: profile?.admin?.createdAt,
      loans: profile?.admin?.loans,
      profilePhoto: profile?.admin?.profilePhoto,
      role: profile?.admin?.permissions?._id || "",
    });
  }, [profile]);

  const [errors, setErrors] = useState({});

  const handleChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
    setErrors({
      ...errors,
      //   [field]: validateField(field, event.target.value),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <section className={styles.user}>
      {loading ? (
        <div className={styles.form__inner}>
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
          <Skeleton variant="rounded" width={"30%"} height={80} />
        </div>
      ) : (
        <>
          <div className={styles.user__details}>
            <div className={styles.user__name}>
              <img alt="" src={formData?.profilePhoto || Logo} />
              <h3>
                {formData.firstName} {formData.surName}
              </h3>
            </div>
            <div className={styles.delete__button}>
              <Button children={"Deactivate"} />
            </div>
          </div>
          <div className={styles.form} onSubmit={handleSubmit}>
            <h1>Profile</h1>
            <div className={styles.form__inner}>
              <CustomInput
                placeholder="First Name"
                value={formData.firstName}
                type="text"
                label="First Name"
                disabled
                onChange={handleChange("firstName")}
                error={errors.firstName}
                className={styles.input}
              />
              <CustomInput
                placeholder="Last Name"
                value={formData.surName}
                type="text"
                label="Last Name"
                disabled
                onChange={handleChange("surName")}
                error={errors.surName}
                className={styles.input}
              />
              <CustomInput
                placeholder="Phone"
                value={formData.phoneNumber}
                type="tel"
                label="Phone"
                disabled
                onChange={handleChange("phoneNumber")}
                className={styles.input}
                error={errors.phoneNumber}
              />

              <CustomInput
                placeholder="email"
                value={formData.email}
                type="text"
                label="Email"
                disabled
                onChange={handleChange("email")}
                className={styles.input}
                error={errors.email}
              />
            </div>
            <div className={styles.form__buttons}>
              <Button
                type="submit"
                className={styles.submit}
                onClick={() => {
                  navigate("/profile/password");
                }}
              >
                Change Password
              </Button>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Profile;
