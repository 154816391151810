// src/components/Users.js
import React, { useState } from "react";
import styles from "./index.module.css";
import CustomTable from "../../common/customTable";
import useCompanies from "./useCompanies";
import { toTitleCase } from "../../../utils/titleCase";
import { Skeleton } from "@mui/material";
import Modal from "@mui/material/Modal";
import ResponsiveDateTimePicker from "../../common/datePicker";
import { useTheme } from "../../../contexts/themeContext";
import Button from "./../../common/button";
import CustomInput from "../../common/customInput";
const Companies = () => {
  const { companiesData, loading, responseLoading, handleCompanyData } =
    useCompanies();
  const [reportOpen, setReportOpen] = useState(false);
  const { darkMode } = useTheme();
  const handleOpen = () => {
    setReportOpen(true);
  };
  const [companyData, setCompanyData] = useState({
    company_name: "",
    interest_rate: "",
    voucher_code: "",
    start_date: new Date(),
    end_date: new Date(),
  });
  const handleClose = () => {
    setReportOpen(false);
  };
  const headCells = [
    {
      field: "id",
      headerName: "SR.No",
      type: "number",
    },
    {
      field: "company_name",
      headerName: "Name",
      type: "text",
    },
    {
      field: "createdAt",
      numeric: false,
      disablePadding: false,
      headerName: "Joined At",
      type: "date",
      valueGetter: (params) => {
        // Convert the value to a Date object if it's in a different format
        return params ? new Date(params) : null;
      },
      valueFormatter: (params) => {
        // Format the date as DD/MM/YYYY for display
        if (params) {
          const date = new Date(params);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        }
        return "";
      },
    },
    {
      field: "interest_rate",
      numeric: true,
      disablePadding: false,
      headerName: "Interest Rate",
    },
    {
      field: "voucher_code",
      numeric: false,
      disablePadding: false,
      headerName: "Voucher Code",
      type: "string",
      flex: 1,
    },
    {
      field: "start_date",
      numeric: true,
      disablePadding: false,
      headerName: "Start Date",
      type: "date",
      valueGetter: (value) => value && new Date(value),
    },
    {
      field: "end_date",
      numeric: true,
      disablePadding: false,
      headerName: "End Date",
      type: "date",
      valueGetter: (value) => value && new Date(value),
    },
    {
      field: "status",
      numeric: true,
      disablePadding: false,
      headerName: "Status",
      type: "string",
      valueGetter: (status) => {
        return (status = `${toTitleCase(status)}`);
      },
      flex: 1,
    },
  ];

  return (
    <section className={styles.users}>
      {loading ? (
        <Skeleton width={"90%"} height={"90%"} variant="rounded" />
      ) : (
        <>
          <div className={styles.report__header}>
            <Button children={"Create Company"} onClick={handleOpen} />
          </div>
          <div className={styles.table}>
            <CustomTable
              headCells={headCells}
              rows={companiesData?.companies || []}
              type={"Companies"}
            />
          </div>
        </>
      )}
      <Modal
        open={reportOpen}
        data-theme={darkMode ? "dark" : "light"}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.modal}>
          <h2>Create Company</h2>
          <div className={styles.container}>
            <div className={styles.container__main}>
              <div className={styles.inner_container}>
                <p>Start Date</p>
                <ResponsiveDateTimePicker
                  onChange={(value) => {
                    const date = new Date(value);
                    const formattedDate = new Date(
                      Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      )
                    );
                    setCompanyData({
                      ...companyData,
                      start_date: formattedDate,
                    });
                  }}
                  value={companyData?.start_date}
                />
              </div>
              <div className={styles.inner_container}>
                <p>End Date</p>
                <ResponsiveDateTimePicker
                  onChange={(value) => {
                    const date = new Date(value);
                    const formattedDate = new Date(
                      Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      )
                    );
                    setCompanyData({
                      ...companyData,
                      end_date: formattedDate,
                    });
                  }}
                  value={companyData?.end_date}
                />
              </div>
              <div className={styles.inner_container}>
                <p>Name</p>
                <CustomInput
                  label={"Company Name"}
                  type="text"
                  className={styles.input}
                  value={companyData?.company_name}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      company_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.inner_container}>
                <p>Interest Rate</p>
                <CustomInput
                  label={"Interest Rate"}
                  type="text"
                  className={styles.input}
                  value={companyData?.interest_rate}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      interest_rate: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.inner_container}>
                <p>Voucher Code</p>
                <CustomInput
                  label={"Voucher Code"}
                  type="text"
                  className={styles.input}
                  value={companyData?.voucher_code}
                  onChange={(e) => {
                    setCompanyData({
                      ...companyData,
                      voucher_code: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.button}>
            <Button
              children={"Create"}
              onClick={async () => {
                await handleCompanyData(companyData);
                handleClose();
              }}
              loading={responseLoading}
            />
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default Companies;
