import { useState, useEffect } from "react";
import useFetchData from "../../../hooks/useFetchData";
import useErrorHandler from "../../../hooks/useErrorHandler";
import usePostData from "../../../hooks/usePostData";
const useAdmin = () => {
  const handleError = useErrorHandler();
  const { data, loading, error } = useFetchData(`/admins/interest`);
  const { data: products } = useFetchData(`/products/all`);

  const { postData } = usePostData();
  const [productData, setProductData] = useState(null);

  const [interestData, setInterestData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setInterestData(data);
      setProductData(products);

      if (error) {
        handleError(error, error.message);
      }
    };

    fetchData();
  }, [data, handleError, error, products]);

  const handleUpdate = async (interestRate, penaltyData) => {
    try {
      setSubmitLoading(true);
      const result = await postData(`/interest`, {
        products: interestRate,
        penalty: penaltyData,
      });
      handleError(result, result.message, "success");
      setSubmitLoading(false);
    } catch (error) {
      handleError(error, error.message);
      setSubmitLoading(false);
      return;
    }
  };
  return {
    interestData,
    loading,
    error,
    handleUpdate,
    submitLoading,
    productData,
  };
};

export default useAdmin;
